const base = APPLICATION_ENV.USER_API;
const userApis = {
  getOssKey: `${base}common/getOssConfig`,
  operation: {
    login: `${base}SupplierLogin/login`, // 登录
    logout: `${base}SupplierLogin/logout`, // 退出登录
    getCode: `${base}SupplierLogin/getCode`, // 获取手机验证码
    promSignUp: `${base}SupplierLogin/promSignUp`, // 商家后台活动报名入口
  },
  back_supplier: {
    view: `${base}back-supplier/supplier/view`, // 查看登陆商家信息
    edit: `${base}back-supplier/supplier/editRealname`, // 修改真实姓名
    isSoboten: `${base}SupplierLogin/isSoboten`, // 校验当前用户是否为智齿商家
    editInfo: `${base}back-supplier/supplier/editInfo`, // 修改商家信息
  },
  role: {
    list: `${base}back-supplier/Role/list`, // 角色列表
    view: `${base}back-supplier/Role/view`, // 角色查看
    add: `${base}back-supplier/Role/add`, // 角色新增
    delete: `${base}back-supplier/Role/delete`, // 角色删除
    edit: `${base}back-supplier/Role/edit`, // 角色编辑
    disable: `${base}back-supplier/Role/disable`, // 角色禁用
    enable: `${base}back-supplier/Role/enable`, // 角色启用
  },
  // nav: {
  //     add: base + 'back-supplier/navigation/add',
  //     edit: base + 'back-supplier/navigation/edit',
  //     del: base + 'back-supplier/navigation/delete',
  //     getlist: base + 'back-supplier/navigation/list',
  //     getMenuGroupList: base + 'back-supplier/navigation/getMenuGroupList'
  //   },
  commonNew: {
    getChildRegion: `${base}common/getChildRegion`, // 获取下级行政区域
    getAllRegion: `${base}common/getAllRegion`, // 获取所有城市
    uploadFile: `${base}common/uploadFile`, // 上传文件
    getBankList: `${base}common/getBankList`, // 获取商家收款账户银行列表
    sendCode: `${base}common/sendCode`, // 发送验证码
    getLogisticList: `${base}common/getLogisticList`, // 获取商家发货快递列表
  },
  navigation: {
    list: `${base}back-supplier/navigation/list`, // 左侧菜单栏列表
  },
  homePage: {
    sales: `${base}/back-supplier/homePage/salesStatistics`, // 首页数据
  },
  verify: {
    verify: `${base}/app-supplier/store/verify`, // 核销
  },
  // 店铺装修
  Navigation: {
    page: `${base}/back-supplier/Navigation/page`, // 店铺装修页面列表
    detail: `${base}/back-supplier/Navigation/detail`, // 详情
    modify: `${base}/back-supplier/Navigation/modify`, // 新增/编辑
    modify_sort: `${base}/back-supplier/Navigation/modify_sort`, // 更改导航排序
    modify_status: `${base}/back-supplier/Navigation/modify_status`, // 更改发布状态
  },
  supplierInfoManage: {
    getQualificationList: `${base}common/getQualificationList`, // 获取商家资质类型列表
    storeGoods: `${base}/app-supplier/store/storeGoodsData`, // 获取商家店铺商品列表
    list: `${base}/backplat/supplierInfoManage/list`, // 商家列表
  },
  settle: {
    getGraph: `${base}captcha/getGraph`, // 图形验证码
    getMessage: `${base}captcha/getMessage`, // 获取短信验证码
    register: `${base}SupplierRegister/register`, // 注册
    getRegisterStatus: `${base}SupplierRegister/getRegisterStatusByMobile`, // 根据手机号获取入驻情况
    getReviewInfo: `${base}SupplierRegister/getReviewInfo`, // 获取当前登陆用户入驻信息
    saveDraft: `${base}SupplierRegister/save_draft`, // 保存草稿
    getReviewInfoBySupplierId: `${base}SupplierRegister/getReviewInfoBySupplierId`, // 根据id获取入驻信息
    submitReview: `${base}SupplierRegister/submit_review`, // 提交审核、编辑重新提交审核
    identifyBusinessLicense: `${base}SupplierRegister/identifyBusinessLicense`, // 营业执照识别接口
    identifyIdCard: `${base}SupplierRegister/identifyIdCard`, // 身份证识别接口
  },
  preferred_supplychain: {
    list: `${base}mallaccount/preferred-supplychain/list`, // 供应链申请列表
    detail: `${base}mallaccount/preferred-supplychain/sup/detail`, // 供应链申请详情
    apply: `${base}mallaccount/preferred-supplychain/sup/apply`, // 供应链合作申请/编辑
    audit: `${base}mallaccount/preferred-supplychain/pref/audit`, // 优选供应链审核
    cooperateStatus: `${base}mallaccount/preferred-supplychain/pref/cooperateStatus`, // 优选供应链更改合作状态
  },

  // 商品详情-获取所有省份列表
  provinceListApi: `${base}common/province/list`,
  // 商品详情-下级区域
  regionShortNameApi: `${base}common/child/region/short/name`,
};

export default userApis;
