import marketApi from './marketApi'
import storeGoodsSetApi from './storeGoodsSetApi'
import userApis from './nb/user'
import adminApis from './nb/admin'
import storeApis from './nb/store'
import goodsApis from './nb/goods'
import orderApis from './nb/order'
import settingApis from './nb/setting'
import supplierApis from './nb/supplier'
import scmApis from './nb/scm'
import commentApis from './nb/comment'
import manageApis from './nb/manage'
import marketingApis from './nb/marketing'

const base = '/apiadmini/'
const Api = {
  // 获取角色
  getRole: `${base}backend/admin/get_role`,
  eventsource: `${base}backend/eventsource/message`, // 事件流
  // 修改密码
  changePwd: `${base}backend/admin/changepwd`,
  // 获取菜单栏
  getMenuList: `${base}backend/menu/get_menu`,
  getOrderNum: `${base}backend/purchase/suppliers_purchase_count`,
  // 退出
  closeAdmin: `${base}backend/admin/logout`,
  // 账户信息
  // admin: {
  //   get_admin_info: base + 'backend/admin/get_admin_info', // 获取个人账号信息
  //   update_admin_info: base + 'backend/admin/update_admin_info', // 修改管理员个人资料
  //   changepwd: base + 'backend/admin/changepwd', // 修改密码
  //   handle_admin: base + 'backend/admin/handle_admin'// 禁用、解禁、删除管理员
  // },
  // 图片api
  get_img_url: `${base}api/oss/get_image`,
  upload_image: `${base}api/Oss/upload_image`,
  // 首页数据
  index: {
    index: `${base}backend/Index/index`, // 首页数据
    saleInfo: `${base}backend/index/store_stat` // 首页销售数据
  },
  // api新增在这里
  login: `${base}backend/admin/login`,
  logout: `${base}backend/admin/logout`,
  captcha: `${base}api/captcha/create_captcha`, // 刷新登录验证码
  goodsmanage1: `${base}backend/category/get_level1`, // 获取一级类目列表
  goodsmanage2: `${base}backend/category/get_level2`, // 获取二级类目列表
  goodsmanage3: `${base}backend/category/get_level3`, // 获取三级类目列表
  getGoodsList: `${base}backend/goods/getlist`, // 获取商品列表
  changeSort: `${base}backend/goods/edit_goods_sort`,
  refreshPrice: `${base}backend/goods/calculate`, // 更新商品价格
  getSiteList: `${base}backend/site/getlist`, // 获取站点下拉列表
  getSuplierList: `${base}backend/site/site_list`, // 获取场馆列表
  getGood: `${base}backend/goods/getgood`, // 获取商品详情
  getRecommendGoods: `${base}backend/goods/get_recommend_list`, // 获取推荐列表
  update_goods_sale: `${base}backend/goods/up_on_sale`, // 单个商品上下架
  set_base_info: `${base}backend/GoodsBaseInfo/set_base_info`, // 单个设置
  pick_up_goods: `${base}backend/goods/pick_up_goods`, // 单个设置自提
  // 创建商品
  getSku: `${base}backend/goods/get_spec`, // 获取商品规格列表
  createGoods: `${base}backend/goods/create_base_info`, // 创建商品 基本信息
  updateGoods: `${base}backend/goods/update_base_info`, // 更新商品
  createSku: `${base}backend/goods/add_spec`, // 创建规格
  addSku: `${base}backend/goods/add_spec_item`, // 创建规格
  delSku: `${base}backend/goods/delete_spec`, // 删除规格
  delitem: `${base}backend/goods/delete_spec_item`, // 删除规格item
  set_goods_info: `${base}backend/goods/get_goods_by_keys`, // 获取商品规格价格信息
  submit_goods_price: `${base}backend/goods/update_spec_goods_price`, // 保存或者更新商品价格
  get_attr_list: `${base}backend/goods/get_attr_list`, // 获取商品属性列表
  update_attr: `${base}backend/goods/edit_attr`, // 更新商品属性
  get_img: `${base}api/image/get`, // 获取图片信息
  save_active: `${base}backend/goods/save_active`, // 进店必买 和 猜你喜欢
  get_active_goods: `${base}backend/goods/get_active_goods`, // 进店必买 和 猜你喜欢 列表
  ajax_save_active: `${base}backend/goods/ajax_save_active`, // 进店必买 和 猜你喜欢 列表

  getaliyun: `${base}backend/video/supply_upload_info`, // 获取阿里云上传凭证和地址
  delvd: `${base}backend/video/delete`, // 删除视频
  getvdinfo: `${base}backend/video/video_info`, // 获取视频封面
  getVdUrl: `${base}backend/video/play_info`, // 获取视频链接
  // 类目
  cate: {
    backselect: `${base}rebuildbackend/ShowCategory/get_goods_category_list`,
    frontselect: `${base}rebuildbackend/ShowCategory/get_show_category_list`,
    addfront: `${base}rebuildbackend/ShowCategory/add`,
    getfront: `${base}rebuildbackend/ShowCategory/edit`,
    updatefront: `${base}rebuildbackend/ShowCategory/update_info`,
    getfrontlist: `${base}rebuildbackend/ShowCategory/getList`,
    isshow: `${base}rebuildbackend/ShowCategory/update_is_show`,
    sort: `${base}rebuildbackend/ShowCategory/update_sort_order`,
    del: `${base}rebuildbackend/ShowCategory/del`,
    getShowCate: `${base}rebuildbackend/ShowCategory/getShowCate`, // 前台分类下拉
    goodsList: `${base}rebuildbackend/ShowCategory/goodsList` // 获取商品列表
  },
  // 标签
  label: {
    getlist: `${base}rebuildbackend/GoodsTag/getList`,
    add: `${base}rebuildbackend/GoodsTag/add`,
    getinfo: `${base}rebuildbackend/GoodsTag/edit`,
    update: `${base}rebuildbackend/GoodsTag/update_info`,
    del: `${base}rebuildbackend/GoodsTag/del`,
    has_goods: `${base}rebuildbackend/GoodsTag/has_related_goods`,
    goods_get_label: `${base}rebuildbackend/GoodsTag/get_tag_list`
  },
  warning: {
    getlist: `${base}backend/store_warning/get_list`,
    get_user_list: `${base}backend/admin/get_ding_member`,
    set: `${base}backend/config/set_store_warning`,
    del: `${base}backend/store_warning/del`,
    add: `${base}backend/store_warning/save`
  },
  // 满减送
  reduceSend: {
    getlist: `${base}rebuildbackend/MjsActive/getList`, // 获取列表
    getinfo: `${base}rebuildbackend/MjsActive/edit`,
    delete: `${base}rebuildbackend/MjsActive/delete_active`, // 删除活动
    delete_level: `${base}rebuildbackend/MjsActive/delete_grade`, // 删除层级
    add: `${base}rebuildbackend/MjsActive/add_or_update`, // 新增或者编辑
    get_class_list: `${base}rebuildbackend/MjsActive/get_cat_list`, // 获取分类列表
    get_sku: `${base}backend/goods/get_goods_spec_detail_list`, // 获取商品sku
    get_goods_list: `${base}backend/Coupon/get_goods_list`, // 获取商品列表
    get_coupon_list: `${base}rebuildbackend/MjsActive/get_coupon_list` // 获取优惠券列表
  },
  // 专题模版
  subject: {
    getofficelist: `${base}rebuildbackend/PluginTemplet/get_material_list`,
    getlist: `${base}rebuildbackend/PluginTemplet/getList`,
    getinfo: `${base}rebuildbackend/PluginTemplet/edit`,
    add: `${base}rebuildbackend/PluginTemplet/add_base_info`,
    update: `${base}rebuildbackend/PluginTemplet/update_base_info`,
    edit: `${base}rebuildbackend/PluginTemplet/update_content`,
    del: `${base}rebuildbackend/PluginTemplet/del`,
    getmjslist: `${base}rebuildbackend/PluginTemplet/get_mjs_list`,
    getcouponlist: `${base}rebuildbackend/PluginTemplet/get_coupon_list`,
    addgoodsbyid: `${base}rebuildbackend/PluginTemplet/add_goods_by_goods_id`,
    addgoodsbymjs: `${base}rebuildbackend/PluginTemplet/add_goods_by_mjs`,
    set_preview: `${base}rebuildbackend/PluginTemplet/set_preview`
  },
  // 发票管理
  invoice: {
    getlist: `${base}rebuildbackend/Invoice/lists`,
    getinfo: `${base}rebuildbackend/Invoice/detail`,
    download: `${base}rebuildbackend/Invoice/download`,
    get_tax_list: `${base}rebuildbackend/InvoiceTaxRate/lists`,
    edit_tax: `${base}rebuildbackend/InvoiceTaxRate/edit`,
    add_tax: `${base}rebuildbackend/InvoiceTaxRate/add`,
    get_seller: `${base}rebuildbackend/InvoiceSeller/detail`,
    edit_seller: `${base}rebuildbackend/InvoiceSeller/edit`,
    add_seller: `${base}rebuildbackend/InvoiceSeller/add`,
    get_seller_list: `${base}rebuildbackend/InvoiceSeller/lists`,
    get_select_list: `${base}rebuildbackend/invoice/getTaxRateList`,
    get_invoice_order: `${base}rebuildbackend/Invoice/getInvoiceByOrder`,
    goods_detail: `${base}/rebuildbackend/Invoice/goods_detail` // 发票详情-商品列表
  },
  // 素材库
  material: {
    getList: `${base}backend/material/get_list`, // 获取列表
    getInfo: `${base}backend/material/get`, // 获取详情
    editMaterial: `${base}backend/material/create`, // 创建
    deleteMaterial: `${base}backend/material/delete`, // 删除
    is_draw: `${base}backend/material/draw` // 是否引用
  },
  // 店铺活动
  storeactive: {
    getlist: `${base}backend/campaignnew/get_list`, // 获取列表
    add: `${base}backend/campaignnew/create`, // 创建
    get: `${base}backend/campaignnew/get`, // 获取详情编辑
    update: `${base}backend/campaignnew/update`, // 编辑
    get_detail: `${base}backend/campaignnew/get_detail` // 查看获取
  },
  // 预售
  presell: {
    getInfo: `${base}backend/prombooking/get`,
    create: `${base}backend/prombooking/create`,
    update: `${base}backend/prombooking/update`,
    getSkuList: `${base}backend/prombooking/get_spec_list`,
    getpage: `${base}backend/prombooking/get_page`,
    getlist: `${base}backend/prombooking/get_list`,
    changeType: `${base}backend/prombooking/set_result`
  },

  // 弹窗管理
  start: {
    h5_getlist: `${base}backend/Bombscreen/get_list`, // 获取列表
    h5_create: `${base}backend/Bombscreen/create`, // 创建弹窗
    h5_delete: `${base}backend/Bombscreen/delete` // 删除弹窗
  },
  // banner管理
  banner: {
    get_list: `${base}backend/banner/get_list`, // 获取列表
    create: `${base}backend/banner/create`, // 创建banner
    update: `${base}backend/banner/update`, // 编辑banner
    get_position: `${base}backend/banner/get_position` // banner位置选择
  },

  // 其他--广告管理
  bannerelse: {
    get_list: `${base}backend/bannerelse/get_list`, // 获取列表
    create: `${base}backend/bannerelse/create`, // 创建
    delete: `${base}backend/bannerelse/delete`, // 删除
    update: `${base}backend/bannerelse/update`, // 编辑
    get_options: `${base}backend/bannerelse/get_options` // 位置选择
  },

  // 配送区域
  deliver: {
    getGoodsList: `${base}backend/goodsshippingarea/get_list`, // 获取商品列表
    getRegion: `${base}backend/goodsshippingarea/get_region_ids`, // 获取城市列表
    getGoodsRegion: `${base}backend/goodsshippingarea/get_goods_region`, // 获取单个商品配送区域列表
    createRegion: `${base}backend/goodsshippingarea/add_region`, // 添加区域
    delete: `${base}backend/Goodsshippingarea/delete`, // 删除区域
    region_children_list: `${base}backend/goodsshippingarea/region_children_list` // 获取区域下一级列表
  },

  orderList: {
    getOrderList: `${base}backend/order/get_list`, // 订单列表
    getOrderListDetail: `${base}backend/order/get`, // 订单详情
    get_invoice_list: `${base}backend/invoice/get_list`,
    goods_sale_list: `${base}backend/GoodsSales/lists`, // 商品销售列表
    goods_sale_expor: `${base}backend/GoodsSales/export`, // 商品销售列表导出
    settlement: `${base}backend/GoodsSales/settlement`, // 发起结算
    edit_order_deliver_no: `${base}backend/order/edit_order_deliver_no`
  },
  adList: {
    // 广告列表
    getAdLists: `${base}backend/ad/get_list`,
    deleteAdList: `${base}backend/ad/update`,
    getPositionID: `${base}backend/ad/get_ad_position`,
    deleteAd: `${base}backend/ad/delete`, // 删除广告
    ajax_status: `${base}backend/ad/ajax_status`, // 是否显示广告
    get_options: `${base}backend/ad/get_options` // 是否显示广告
  },
  purchaseOrder: {
    purchaseOrders: `${base}backend/purchase/get_list`, // 采购单列表
    purchaseOrderDetail: `${base}backend/purchase/get`, // 采购单详情列表
    addPost: `${base}backend/purchase/get_logitics_goods`, // 添加物流信息列表
    addPostComp: `${base}backend/purchase/get_shipping`, // 添加物流公司
    sentData: `${base}backend/purchase/set_goods_logitics`, // 保存数据
    configSentGood: `${base}backend/purchase/confirm_logitics`, // 确认发货
    editlogitics: `${base}backend/purchase/update_goods_logitics`, // 编辑物流信息
    get_remark_list: `${base}backend/purchase/get_remark_list`,
    create_remark: `${base}backend/purchase/create_remark`,
    erp: `${base}backend/purchase/get_erp_list`,
    getGoodsBySupplier: `${base}backend/purchase/getGoodsListBySupplier`,
    set_delivery: `${base}backend/purchase/set_delivery`, // 执行发货
    import_expo_purchase: `${base}backend/purchase/import_expo_purchase`, // 导出农博会采购单
    get_return_order: `${base}/backend/purchase/get_return_order`,
    delete_purchase_item: `${base}backend/purchase/purchase_delete`, // 删除子订单
    receive_hotel_order: `${base}backend/purchase/receive_hotel_order`, // 商家接单/拒绝接单
    batchImport: `${base}backend/purchase/deliver_goods_import`,
    travelOrderDetail: `${base}backend/purchase/get_travel`, // 农旅订单详情
    travelApplied: `${base}backend/purchase/applied`
  },
  pack: {
    getlist: `${base}backend/package/get_list`,
    update: `${base}backend/package/save_shipping`,
    confirm: `${base}backend/package/confirm_logitics`,
    more_confirm: `${base}backend/package/more_confirm`,
    getinfo: `${base}backend/package/get_info`,
    getFail: `${base}backend/package/getFailExcel`
  },
  adAdvertiserment: {
    getAdMasseges: `${base}backend/ad/add`,
    baseSet: `${base}backend/config/update`,
    siteManege: `${base}backend/site/get_site_list`,
    siteOrder: `${base}backend/site/update`, // 站点排序及推荐
    create_site: `${base}backend/site/addSite`, // 增加站点
    sortList: `${base}backend/ad/sort_ad` // 广告排序
  },
  searchManage: {
    searchList: `${base}backend/find/get_old_list`,
    handleEdit: `${base}backend/find/update_old`,
    editorFind: `${base}backend/find/update_old`
  },
  editor: {
    editorGoods: `${base}backend/goods/get_goods_by_keys`
  },
  img: {
    set_img: `${base}api/image/upload`, // 上传图片
    get_img_info: `${base}api/image/get`, // 获取图片信息
    get_list: `${base}api/image/get_list`, // 获取图片列表
    update_img: `${base}api/image/update` // 更新图片信息
  },
  addClassify: {
    addclassify: `${base}backend/category/add` // 添加分类
  },
  addcategory: {
    addcategory: `${base}backend/category/get_cat_list`, // 管理商品添加分类
    editor: `${base}backend/category/update` // 管理商品添加分类编辑
  },
  // 店铺
  storeKeeper: {
    get_store_list: `${base}backend/store/getlist`, // 获取店铺列表
    resetPwd: `${base}backend/store/reset_pay_pwd`, // 重置店铺支付密码
    get_team_list: `${base}backend/store/get_team_list`, // 团队成员列表
    get_team_order: `${base}backend/store/get_team_order`, // 团队销售记录
    get_invite_users: `${base}backend/store/get_invite_users`, // 专属客户
    getTeamNewcomer: `${base}backend/store/getTeamNewcomer`, // 团队销售
    store_apply_list: `${base}/backend/store/store_apply_list`, // 获取自购申请列表
    deal_store_apply: `${base}backend/store/deal_store_apply` // 处理申请
  },
  user: {
    get_user_base_info: `${base}backend/User/get_user_base_info`, // 用户详情
    get_coupon_list: `${base}backend/User/get_coupon_list`, // 优惠券列表
    get_address_list: `${base}backend/User/get_address_list`, // 收获地址列表
    update_user_base_info: `${base}backend/User/update_user_base_info`, // 修改用户信息
    add_recommend_follow: `${base}backend/user/add_recommend_follow`, // 设置推荐关注
    del_recommend_follow: `${base}backend/user/del_recommend_follow`, // 取消推荐关注
    get_info_by_mobile: `${base}backend/user/get_info_by_mobile`, // 根据手机号获取用户信息（注销）
    del_user: `${base}backend/user/del_user` // 注销用户
  },
  // 团队
  team: {
    ready_team_list: `${base}backend/Team/ready_team_list`, // 预备客户经理列表
    setDirector: `${base}backend/Team/setDirector`, // 设为客户经理
    ready_manager_list: `${base}backend/Team/ready_manager_list`, // 预备经理列表
    sync_wechat: `${base}backend/team/sync_wechat`, // 同步信息到前台
    get_store_info: `${base}backend/Store/store_detail`, // 获取店铺信息
    update_store_info: `${base}backend/Store/update_store_base_info`,
    get_income_list: `${base}backend/Store/income_list`, // 获取收益列表
    get_wait_income_list: `${base}backend/Store/wait_income_list`, // 获取待收益列表
    get_balance_list: `${base}backend/Store/left_income_list`, // 获取余额列表
    get_bank_list: `${base}backend/Store/bank_card_list`, // 获取银行卡列表
    add_wechat: `${base}backend/team/add_wechat`, // 团队客户经理添加微信号和二维码
    base_salary_list: `${base}backend/team/base_salary_list`, // 辅导底薪月报列表
    base_salary_detail: `${base}backend/team/base_salary_detail`, // 辅导底薪培育明细
    pay_salary: `${base}backend/team/pay_salary`, // 发放辅导底薪
    teach_income_check: `${base}backend/team/teach_income_check`, // 辅导底薪审核
    team_rank_list: `${base}backend/team/team_rank_list`, // 团队业绩排行榜
    team_store_income: `${base}backend/team/team_store_income`, // 收益排行饼形图
    team_director_income: `${base}backend/team/team_director_income`, // 本月团队经理收益
    team_sales: `${base}backend/team/team_sales`, // 按天取团队销售额
    team_member_sales: `${base}backend/team/team_member_sales`, // 按天取团队成员销售额
    team_list: `${base}backend/team/team_list`, // 战队列表
    team_detail: `${base}backend/team/team_detail`, // 战队详情
    edit_team: `${base}backend/team/edit_team` // 修改推荐、排序、冻结及解冻战队
  },
  // 优秀店铺
  ExcellentStore: {
    get_store_list: `${base}backend/ExcellentStore/get_store_list`, // 获取优秀店铺列表
    add_store: `${base}backend/ExcellentStore/add_store`, // 添加店铺
    add_check: `${base}backend/ExcellentStore/add_check`, // 添加之前的校验
    del_store: `${base}backend/ExcellentStore/del_store`, // 删除优秀店铺
    order_store: `${base}backend/ExcellentStore/order_store` // 对优秀店铺进行排序
  },
  // 商城用户
  storeUser: {
    getlist: `${base}backend/user/get_user_coin_list`,
    update: `${base}backend/user/update`
  },
  Store: {
    store_rank_list: `${base}backend/store/store_rank_list`, // 店铺排行榜
    get_director_list: `${base}backend/store/get_director_list`, // VIP会员经理管理
    forbid_store: `${base}backend/Store/forbid_store`, // 禁用VIP
    get_invite_list: `${base}backend/store/get_invite_list`, // 获取拉新升级店主列表
    bind_fans: `${base}backend/store/bind_fans`, // 后台绑定专属粉丝
    sales_amount_list: `${base}backend/store/sales_amount_list`, // 获取店铺净销售额详情
    store_detail: `${base}backend/store/detail` // 店铺信息详情
  },
  // 限时购
  limited: {
    get_limited: `${base}backend/goods/get_prom_limit_active`, // 获取限时购
    get_limited_list: `${base}backend/goods/get_prom_list`, // 获取限时购列表
    add_prom_active: `${base}backend/goods/edit_prom_active`, // 添加或者更新限时购
    get_spec_price: `${base}backend/goods/get_prom_spec`, // 获取规格价格列表
    delete_limit: `${base}backend/goods/delete_prom_limit_active`, // 删除
    add_goods_to_prom: `${base}backend/goods/add_goods_to_prom`, // 添加单个商品到当前的限时购
    delete_prom_active_goods: `${base}backend/goods/delete_prom_active_goods`, // 单个商品取消参加当前的限时购
    update_prom_active_goods: `${base}backend/goods/update_prom_active_goods`, // 保存单个商品的折扣
    update_prom_spec_price: `${base}backend/goods/update_prom_spec_price` // 修改折扣价
  },
  // 文案管理
  ofc_control: {
    getlist: `${base}backend/activity/get_list`,
    editItem: `${base}backend/activity/create`
  },
  // 退货
  returnGoods: {
    return_goods_list: `${base}backend/order/get_return_list`, // 获取退货列表
    return_get_info: `${base}backend/order/get`, // 获取退货信息
    deal_return: `${base}backend/order/deal_return`, // 同意或者拒绝退货
    recover_refund: `${base}backend/order/recover_refund` // 恢复退货
  },
  // 提现审核
  withdraw: {
    getList: `${base}backend/trans/getlist`, // 获取列表
    changeStatus: `${base}backend/trans/update`, // 拒绝
    goSuccess: `${base}backend/trans/dotrans` // 通过
  },
  // 退款审核
  refundaudit: {
    getList: `${base}backend/order/get_refund_money_list`, // 获取列表
    goSuccess: `${base}backend/order/refund`, // 同意退款
    check_order_status: `${base}backend/order/check_order_status` // 检测订单状态
  },
  // 财务报表
  statement: {
    getpage: `${base}backend/finance/get_page`,
    getlist: `${base}backend/finance/get_list`
  },
  idcardverify: {
    list: `${base}backend/User/certification_list`,
    operatorList: `${base}backend/User/operator_list`,
    check: `${base}backend/User/check_real_info`
  },
  // 开发专用
  it: {
    get_day_sts: `${base}backend/stat/goods_days`, // 获取日销售列表
    get_active_user: `${base}backend/stat/overview_month`, // 获取活跃用户
    get_channel_list: `${base}backend/channel/channel_list`, // 获取渠道列表
    edit_channel: `${base}backend/channel/add_channel`, // 添加&编辑渠道
    get_sms_list: `${base}backend/smstemplate/getlist`, // 获取短信列表
    create_sms: `${base}backend/smstemplate/create`, // 创建短信
    update_sms: `${base}backend/smstemplate/update`, // 更新短信
    smslog_list: `${base}backend/smslog/getlist`, // 获取短信日志

    get_stafftel_list: `${base}backend/Employee/getlist`, // 获取员工手机号列表
    create_stafftel: `${base}backend/Employee/create`, // 创建员工手机号

    itlog_getlist: `${base}backend/log/get_list`, // 日志查看获取列表

    send_sms: `${base}backend/smslog/send_sms`, // 查询短信验证码
    ajax_find_belong: `${base}backend/order/ajax_find_belong`, // 查询订单归属信息
    ajax_belong: `${base}backend/order/ajax_belong`, // 修改订单归属信息

    // 用户调试
    get_debug_list: `${base}backend/debug_user/get_list`,
    create_debug: `${base}backend/debug_user/create`,
    delete_debug: `${base}backend/debug_user/delete`
  },
  // 数据
  data: {
    // 创建活动
    create_activity: `${base}backend/campaign/create`,
    // 获取活动列表
    get_activity_list: `${base}backend/campaign/get_camp_title_list`,
    // 获取数据列表
    get_list: `${base}backend/campaign/get_list`,
    // 奖励发放
    send_reward: `${base}backend/campaign/reward`
  },
  // 优惠券
  discountCoupon: {
    getlist: `${base}backend/coupon/get_list`,
    close: `${base}backend/coupon/to_expire`,
    getgoodslist: `${base}backend/coupon/get_goods_list`, // 优惠券获取商品列表
    getcatlist: `${base}backend/coupon/get_cat_list`,
    create: `${base}backend/coupon/create`,
    update: `${base}backend/coupon/update`,
    getinfo: `${base}backend/coupon/get`,
    provide: `${base}backend/coupon/send_to_user`,
    getrecordlist: `${base}backend/coupon/get_record_list`
  },
  // 厨币
  acb: {
    getlist: `${base}backend/coin/get_list`,
    provide: `${base}backend/coin/send_to_user`,
    get_send_list: `${base}backend/coin/get_send_list`
  },

  // 供应商
  // supplier: {
  //   getlist: base + 'backend/suppliers/get_list',
  //   update: base + 'backend/suppliers/update',
  //   add: base + 'backend/suppliers/add',
  //   get: base + 'backend/suppliers/info',
  //   stop: base + 'backend/suppliers/is_stop',
  //   selectList: base + 'backend/suppliers/get_suppliers_by_site',
  //   // 获取供应商列表(根据登录属性)
  //   selectList2: base + 'backend/suppliers/get_suppliers_list',
  //   // 获取全部供应商下拉列表
  //   selectList3: base + 'backend/suppliers/get_suppliers'
  // },
  // 优惠券码
  ticket: {
    getlist: `${base}backend/couponcode/get_list`,
    create: `${base}backend/couponcode/create`,
    close: `${base}backend/couponcode/update`,
    getcodelist: `${base}backend/code/get_list`,
    code_close: `${base}backend/code/expire`,
    down: `${base}backend/code/down_list`,
    code_list: `${base}backend/Couponcode/code_list`, // 兑换码列表
    add_code: `${base}backend/Couponcode/add_code`, // 新建厨币兑换码/优惠券兑换码/抽奖机会兑换码
    sn_list: `${base}backend/Couponcode/sn_list`, // 查看序列号（编码查询）
    lottery_list: `${base}backend/Couponcode/lottery_list`, // 抽奖活动列表
    expire_code: `${base}backend/couponcode/expire_code`, // 使兑换码失效
    expire_sn: `${base}backend/couponcode/expire_sn` // 使序列号失效
  },
  // 充值卡
  refillCard: {
    getlist: `${base}backend/rechargecard/get_list`,
    close: `${base}backend/rechargecard/update`,
    create: `${base}backend/rechargecard/create`,
    add_code: `${base}backend/rechargecard/add_code_sn`
  },
  getsuglist: `${base}backend/suggest/get_list`, // 获取用户建议列表

  // 结算单
  closeOrder: {
    getlist: `${base}backend/settlement/get_list`,
    get_purchase_list: `${base}backend/settlement/get_purchase_list`,
    get_return_list: `${base}backend/settlement/get_return_list`,
    get_detail: `${base}backend/settlement/get_info`,
    changestatus: `${base}backend/settlement/set_status`,
    changePrice: `${base}backend/settlement/ajax_save`,
    get_base_info: `${base}backend/settlement/get_settlement_more`,
    edit: `${base}backend/settlement/save`,
    downDetail: `${base}backend/settlement/export_purchase`,
    update_shop_price: `${base}backend/Settlement/update_shop_price`, // 修改结算单售后单退款金额
    invoice_info: `${base}backend/Settlement/invoice_info`, // 获取开票信息及寄票地址
    set_invoice_info: `${base}backend/Settlement/set_invoice_info`, // 设置开票信息及寄票地址
    get_settlement_list: `${base}backend/Settlement/get_settlement_list`, // 供应商结算确认列表
    audit_list: `${base}backend/Settlement/audit_list`, // 供应商打款信息审核列表
    handle_audit: `${base}backend/Settlement/handle_audit`, // 处理审核
    update_supplier_info: `${base}backend/Settlement/update_supplier_info`, // 设置供应商收款信息
    get_supplier_info: `${base}backend/Settlement/get_supplier_info`, // 查看供应商收款信息
    sure_settle: `${base}backend/Settlement/sure_settle`, // 供应商确认结算单
    get_deduction_list: `${base}backend/settlement/get_deduction_list`, // 结算列表中扣款单列表
    new_settlement_list: `${base}backend/Settlement/get_list`, // 供应商新结算确认列表
    settlement_list: `${base}backend/GoodsSettlement/lists`, // 结算列表
    withdraw_record: `${base}backend/balance/get_supplier_money_list`, // 提现记录
    balance_list: `${base}backend/balance/get_balance_detail_list`, // 余额列表
    get_supplier_money: `${base}backend/balance/get_supplier_money`, // 获取提现金额信息
    settlement_export: `${base}backend/GoodsSettlement/export`, // 结算导出
    apply_withdraw: `${base}backend/balance/apply`, // 申请提现
    close_detail: `${base}backend/GoodsSettlement/detail` // 结算详情
  },
  account: {
    getlist: `${base}backend/admin/get_list`, // 获取帐号
    create: `${base}backend/admin/create`, // 创建帐号
    update: `${base}backend/admin/update`,
    lock: `${base}backend/admin/lock_or_unlock`,
    reset_pwd: `${base}backend/admin/reset_pwd` // 重置密码
  },
  // role: {
  //   getlist: base + 'backend/role/get_list',
  //   add: base + 'backend/role/create',
  //   update: base + 'backend/role/update',
  //   del: base + 'backend/role/delete',
  //   get_role_menu: base + 'backend/role/get_role_menu_list',
  //   submit_menu: base + 'backend/role/assign_menu',
  //   get_options: base + 'backend/role/get_options'
  // },
  // 活动信息录入
  entryAc: {
    getlist: `${base}backend/promotion/get_list`,
    add: `${base}backend/promotion/create`,
    update: `${base}backend/promotion/update`,
    del: `${base}backend/promotion/delete`
  },
  // 钉钉信息
  ding: {
    get_detail: `${base}backend/ding/get_department_detail`, // 获取部门员工详情
    getlist: `${base}backend/ding/get_department_list`, // 获取钉钉部门列表
    setInfo: `${base}backend/ding/set_ding_info` // 设置对应的钉钉信息
  },
  // 消息中心
  Msg: {
    get_msg_list: `${base}backend/Msg/get_msg_list`, // 消息列表
    add_msg: `${base}backend/Msg/add_msg`, // 添加消息
    msg_detail: `${base}backend/Msg/msg_detail`, // 查看消息详情
    msg_cancel: `${base}backend/Msg/msg_cancel`, // 取消消息的发送
    mail_list: `${base}backend/Msg/mail_list`, // 站内信列表
    get_unread_num: `${base}backend/msg/get_suppliers_unread_num`, // 获取未读站内信数量
    read: `${base}backend/Msg/read`, // 将未读消息更新成已读
    get_gysMsg_list: `${base}backend/msg/suppliers_msg_list`, // 供应商消息列表
    get_qr_code: `${base}backend/suppliers/get_qr_code`, // 公众号二维码图片
    get_user_list: `${base}backend/suppliers/message_user_list`, // 公众号关注列表
    edit_msg_type: `${base}backend/suppliers/set_msg_type`, // 设置消息类型
    del_msg_type: `${base}backend/suppliers/delete_user`, // 删除关注用户
    read_gys: `${base}backend/msg/set_msg_is_read` // 供应商消息改为已读
  },
  // 商品相关
  goods: {
    get_coupon: `${base}backend/goods/get_coupon`, // 优惠券列表
    update_spec_goods_coupon: `${base}backend/goods/update_spec_goods_coupon`, // 商品优惠券添加修改
    adjust_price_list: `${base}backend/goods/adjust_price_list`, // 获取调价单列表
    get_level1: `${base}backend/category/get_level1`, // 获取商品分类
    get_goods_list: `${base}backend/goods/get_goods_list`, // 获取商品列表
    get_goods_spec: `${base}backend/goods/get_goods_spec`, // 根据商品id获取商品规格
    add_adjust_price: `${base}backend/goods/add_adjust_price`, // 新建和修改调价单
    get_operator_list: `${base}backend/goods/get_operator_list`, // 获取操作人列表
    get_detail: `${base}backend/goods/get_detail`, // 获取调价单详情
    del_adjust_price: `${base}backend/goods/del_adjust_price`, // 取消调价单
    get_now_operator: `${base}backend/goods/get_now_operator`, // 获取当前操作人id和名称
    sku_vr_goods_list: `${base}backend/goods/sku_vr_goods_list`, // 获取虚拟商品sku列表
    update_sku_vr_goods: `${base}backend/goods/update_sku_vr_goods`, // 新建商品虚拟商品
    update_goods_name: `${base}backend/goods/update_goods_name`, // 更新商品名称
    copy_goods: `${base}backend/goods/copy_goods`, // 复制商品
    get_goods_by_ids: `${base}backend/goods/get_goods_by_ids`, // 商品--获取多商品数据
    update_goods_flag: `${base}backend/Goods/update_goods_flag`, // 更改商品是否关注标识
    get_base_info: `${base}backend/GoodsBaseInfo/get_base_info`, // 新版-商品信息
    save: `${base}backend/GoodsBaseInfo/save`, // 新版-商品信息编辑
    goods_save: `${base}backend/goods/save`, // 1.5版本商品新增或编辑
    sku_delete: `${base}backend/goods/sku_delete`, // sku：删除
    getlist: `${base}backend/goods/getlist`, // 商品列表
    goods_info_draft: `${base}backend/goods/goods_info_draft`, // 商品编辑详情
    goods_info_online: `${base}backend/goods/goods_info_online`, // 商品详情-生效
    promise_info: `${base}backend/goods/promise_info`, // 商品合格证信息
    goods_qrcode: `${base}backend/goods/select_qrcode`, // 商品二维码
    store_qrcode: `${base}backend/suppliers/select_qrcode`, // 商家二维码
    house_price_list: `${base}backend/goodsstay/getlist`, // 房价管理
    house_detail: `${base}backend/goodsstay/edit`, // 编辑房价
    wholesale_house_detail: `${base}backend/goodsstay/edit_range`, // 批量编辑房价
    house_price_record: `${base}backend/goodsstaylog/getlist` // 房价记录
  },
  // 安厨学院
  studyessay: {
    update_essay_class: `${base}backend/studyessay/update_essay_class`, // 安厨学院文章分类添加修改删除
    get_essay_list: `${base}backend/studyessay/get_essay_list`, // 安厨学院文章列表
    update_essay: `${base}backend/studyessay/update_essay`, // 安厨学院文章添加修改删除
    get_essay_class_list: `${base}backend/studyessay/get_essay_class_list`, // 安厨学院文章类型列表
    essay_class_detail: `${base}backend/studyessay/essay_class_detail`, // 安厨学院文章类型详情
    essay_detail: `${base}backend/studyessay/essay_detail`, // 安厨学院文章详情
    get_essay_top_list: `${base}backend/studyessay/get_essay_top_list`, // 安厨学院置顶文章列表
    update_essay_top: `${base}backend/studyessay/update_essay_top`, // 安厨学院置顶文章添加修改删除
    essay_top_detail: `${base}backend/studyessay/essay_top_detail` // 安厨学院置顶文章详情
  },
  // 心得分类
  SocialCate: {
    add_social_cate: `${base}backend/SocialCate/add_social_cate`, // 新增分类
    social_cate_list: `${base}backend/SocialCate/social_cate_list`, // 分类列表
    del_social_cate: `${base}backend/SocialCate/del_social_cate`, // 删除分类
    update_social_cate: `${base}backend/SocialCate/update_social_cate`, // 编辑心得分类 (名称,是否显示,排序)
    get_cate: `${base}backend/SocialCate/get_cate` // 获取前台社区分类
  },
  // 心得列表
  SocialArticle: {
    social_article_list: `${base}backend/SocialArticle/social_article_list`, // 心得列表
    get_all_robot: `${base}backend/SocialArticle/get_all_robot`, // 获取所有虚拟用户(不分页)
    get_all_cate: `${base}backend/SocialArticle/get_all_cate`, // 获取所有心得分类(不分页)
    add_social_article: `${base}backend/SocialArticle/add_social_article`, // 添加心得内容
    edit_social_article: `${base}backend/SocialArticle/edit_article`, // 编辑心得内容
    socoal_article_detail: `${base}backend/SocialArticle/socoal_article_detail`, // 获取心得统计信息
    update_social_article: `${base}backend/SocialArticle/update_social_article`, // 对单个心得操作(点赞,添加分类,推荐,审核.删除,发布)
    associated_goods_list: `${base}backend/SocialArticle/associated_goods_list`, // 查看关联商品列表
    add_comment: `${base}/backend/SocialArticle/add_comment`, // 添加评论
    uedit: `${base}backend/SocialArticle/uedit`, // 编辑器上传接口
    edit_article: +'backend/SocialArticle/add_social_article', // 编辑文章
    article_list: `${base}backend/SocialArticle/article_list`, // 文章列表
    get_article_info: `${base}backend/SocialArticle/get_article_info`, // 编辑心得或文章前获取详情
    subject_list: `${base}backend/SocialArticle/subject_list`, // 话题列表
    edit_subject: `${base}backend/SocialArticle/edit_subject`, // 编辑话题
    subject_detail: `${base}backend/SocialArticle/subject_detail`, // 话题详情
    publish_subject: `${base}backend/SocialArticle/publish_subject`, // 发布话题
    add_subject: `${base}backend/SocialArticle/add_subject`, // 新增话题
    del_subject: `${base}backend/SocialArticle/del_subject`, // 删除话题
    get_subject_list: `${base}backend/SocialArticle/get_subject_list`, // 获取下拉框话题列表
    get_article_list: `${base}backend/SocialArticle/get_article_list`, // 商品编辑下拉框获取心得
    update_sort: `${base}backend/SocialArticle/update_sort`, // 文章或心得排序
    update_subject_sort: `${base}backend/SocialArticle/update_subject_sort`, // 话题排序
    article_show: `${base}backend/SocialArticle/article_show`, // 展示或取消展示文章
    excellent: `${base}backend/SocialArticle/excellent` // 评优或取消评优心得
  },
  // 虚拟用户
  VirtualUser: {
    get_user_list: `${base}backend/VirtualUser/get_user_list`, // 获取用户列表
    add_virtual_user: `${base}backend/VirtualUser/add_virtual_user`, // 新增虚拟用户
    virtual_user_list: `${base}backend/VirtualUser/virtual_user_list`, // 获取虚拟用户列表
    virtual_user_detail: `${base}backend/VirtualUser/virtual_user_detail`, // 获取虚拟用户详情
    update_base_info: `${base}backend/VirtualUser/update_base_info`, // 编辑虚拟用户基本信息
    follow_user_list: `${base}backend/VirtualUser/follow_user_list`, // 获取关注用户列表和未关注用户列表
    follow_user_add_or_del: `${base}backend/VirtualUser/follow_user_add_or_del`, // 添加或移除关注列表
    fans_list: `${base}backend/VirtualUser/fans_list` // 粉丝列表
  },
  // 评论
  SocialComment: {
    social_comment_list: `${base}backend/SocialComment/social_comment_list`, // 评论列表
    update_social_comment: `${base}backend/SocialComment/update_social_comment` // 审核或删除评论
  },
  // 活动
  activity: {
    activity_list: `${base}backend/activity/activity_list`, // 丰收节活动
    add_activity_goods: `${base}backend/activity/add_activity_goods`, // 新增活动商品
    del_activity_goods: `${base}backend/activity/del_activity_goods`, // 删除活动商品
    edit_viral_num: `${base}backend/activity/edit_viral_num`, // 修改虚拟点赞数
    prom_year_list: `${base}backend/activity/prom_year_list`, // 年货节奖励列表
    add_prom_year_stock: `${base}backend/activity/add_prom_year_stock`, // 新建活动奖励
    del_prom_year_stock: `${base}backend/activity/del_prom_year_stock`, // 删除年货节活动奖励
    prom_stock_detail: `${base}/backend/activity/prom_stock_detail`, // 奖励明细
    update_prom_year_stock: `${base}/backend/activity/update_prom_year_stock`, // 更新年货节奖励
    add_prom_reward: `${base}/backend/activity/add_prom_reward`, // 新增奖励工具
    prom_reward_list: `${base}/backend/activity/prom_reward_list`, // 奖励工具列表
    prom_detail: `${base}/backend/activity/prom_detail`, // 编辑奖励前获取详情
    update_prom_reward: `${base}/backend/activity/update_prom_reward`, // 编辑奖励工具
    reward_detail: `${base}/backend/activity/reward_detail`, // 每日奖励明细
    del_prom_reward: `${base}/backend/activity/del_prom_reward`, // 删除奖励
    create_qrcode: `${base}backend/activity/create_qrcode`, // 生成自定义小程序码
    download_qrcode: `${base}/backend/activity/download_qrcode` // 下载小程序二维码
  },
  // 免费升级
  freeOpen: {
    list: `${base}backend/Store/free_store_list`,
    create: `${base}backend/Store/add_free_store`,
    edit: `${base}backend/Store/update_free_limit`,
    del: `${base}backend/Store/del_free_store`,
    refresh_code: `${base}backend/Store/refresh_code`
  },
  // 邀请店铺活动
  promotion: {
    free_invite_store_list: `${base}backend/promotion/free_invite_store_list`, // 列表
    create_vr_store: `${base}backend/promotion/create_vr_store`, // 创建用户
    update_vr_store: `${base}backend/promotion/update_vr_store`, // 更新信息
    invite_fans_list: `${base}backend/promotion/invite_fans_list`, // 邀粉丝记录列表
    add_vr_record: `${base}backend/promotion/add_vr_record`, // 新增虚拟记录
    update_vr_record: `${base}backend/promotion/update_vr_record` // 修改邀请粉丝记录
  },
  // 拼团
  group: {
    list: `${base}backend/TempGroup/get_group_list`,
    get: `${base}backend/TempGroup/group_detail`,
    edit: `${base}backend/TempGroup/add_prom_group`
  },
  // 拼团工具
  fightGroup: {
    get_group_list: `${base}backend/group/get_group_list`, // 拼团列表
    create: `${base}backend/group/create`, // 创建拼团
    delete: `${base}backend/group/delete_group`, // 删除拼团
    get_group_info: `${base}backend/group/get_group_info`, // 编辑拼团的信息
    save: `${base}backend/group/save`, // 保存拼团
    get_goods_sku: `${base}backend/group/get_goods_sku` // 获取商品sku
  },
  // 物流预警
  logisticsWarn: {
    list: `${base}backend/package/logistics_warning`,
    detail: `${base}backend/package/logistics_detail`,
    ship_list: `${base}backend/config/shipping_warning_list`,
    change_ship: `${base}backend/config/set_shipping_warning`,
    company: `${base}backend/purchase/get_shipping`,
    cancel_warning: `${base}backend/Package/cancel_warning` // 消除物流预警
  },
  // 店铺运营
  storeOperator: {
    list: `${base}backend/store/get_store_list`,
    set_operator: `${base}backend/store/set_operator`,
    get_operator_list: `${base}backend/store/get_operator_list`
  },
  // 农博会
  expo: {
    get_hall_list: `${base}/backend/expo/get_hall_list`,
    new_or_update: `${base}/backend/expo/new_or_update`, // 新增或更新展馆
    hall_detail: `${base}/backend/expo/hall_detail`, // 展馆详情
    get_goods_list: `${base}/backend/expo/get_goods_list`, // 添加商品时获取商品列表
    del_goods: `${base}/backend/expo/del_goods`, // 删除商品
    get_recommend_list: `${base}backend/expo/get_recommend_list`, // 农博会推荐商品列表
    set_recommend: `${base}backend/expo/set_recommend`, // 设置全部列表中的商品推荐
    sort_in_all: `${base}backend/expo/sort_in_all` // 农博会更新推荐商品的推荐值
  },
  // 送礼
  Present: {
    get_card_list: `${base}/backend/Present/get_card_list`, // 贺卡列表
    add_card: `${base}/backend/Present/add_card`, // 新增贺卡
    update_card: `${base}/backend/Present/update_card`, // 修改卡片信息
    del_card: `${base}/backend/Present/del_card`, // 删除贺卡
    card_category_list: `${base}backend/Present/card_category_list`, // 贺卡分类的 分类列表
    card_category_save: `${base}backend/Present/card_category_save`, // 贺卡分类的 新增与编辑
    card_category_del: `${base}backend/Present/card_category_del`, // 贺卡分类的 删除
    list_present_category: `${base}/backend/Present/list_present_category`, // 礼品分类-列表
    present_category_save: `${base}/backend/Present/present_category_save`, // 礼品分类-新增与编辑
    present_category_del: `${base}backend/Present/present_category_del`, // 礼品分类-删除
    list_category_goods_list: `${base}backend/Present/list_category_goods_list`, // 礼品分类下商品-列表
    present_category_goods_add: `${base}backend/Present/present_category_goods_add`, // 礼品分类下商品-新增（支持批量）
    present_category_goods_update: `${base}backend/Present/present_category_goods_update`, // 礼品分类下商品-编辑
    present_category_goods_del: `${base}backend/Present/present_category_goods_del` // 礼品分类下商品-删除
  },
  // 试用店铺
  tryStore: {
    try_store_list: `${base}/backend/store/try_store_list`, // 试用店铺列表
    check_from_store: `${base}/backend/store/check_from_store` // 操作自购前先判断申请者上级
  },
  KoiFish: {
    record_list: `${base}/backend/KoiFish/record_list`, // 记录列表
    get_goods_list: `${base}/backend/KoiFish/get_goods_list`, // 获取商品列表
    get_goods_spec: `${base}/backend/KoiFish/get_goods_spec`, // 获取商品规格
    get_now_operator: `${base}/backend/KoiFish/get_now_operator`, // 获取当前操作人
    add_or_update_record: `${base}backend/KoiFish/add_or_update_record`, // 添加或修改记录
    get_operator_list: `${base}/backend/KoiFish/get_operator_list`, // 获取操作人列表
    record_detail: `${base}/backend/KoiFish/record_detail`, // 记录详情
    del_record: `${base}/backend/KoiFish/del_record`, // 删除记录
    create_order: `${base}/backend/KoiFish/create_order` // 手动生成锦鲤订单
  },
  stat: {
    no_test_order: `${base}/backend/stat/no_test_order`, // 客服数据
    user_stat: `${base}/backend/stat/user_stat`, // 用户概况及趋势
    user_active: `${base}/backend/stat/user_active`, // 用户活跃
    store_stat: `${base}/backend/stat/store_stat`, // VIP会员概况及趋势
    store_active: `${base}/backend/stat/store_active`, // VIP会员活跃
    store_rank: `${base}/backend/stat/store_rank`, // VIP会员统计
    team_rank: `${base}/backend/stat/team_rank`, // 团队排行
    goods_stat: `${base}/backend/stat/goods_stat`, // 商品概况
    cate_rank: `${base}/backend/stat/cate_rank`, // 分类排行
    goods_rank: `${base}/backend/stat/goods_rank`, // 商品排行
    trade_stat: `${base}backend/stat/suppliers_trade_stat`, // 交易概况
    trade_area: `${base}/backend/stat/trade_area`, // 地域分布
    trade_content: `${base}/backend/stat/trade_content`, // 交易构成
    get_city_order: `${base}/backend/stat/get_city_order`, // 根据省份获取市级订单信息
    all_data: `${base}backend/stat/all_data`, // 数据大屏
    get_spec: `${base}backend/stat/get_spec` // 商品排行榜获取规格详情
  },
  TempNewer: {
    prom_list: `${base}backend/TempNewer/prom_list`, // 查看新人专享活动列表
    save: `${base}backend/TempNewer/save`, // 添加/编辑新人专享活动
    del_prom: `${base}backend/TempNewer/del_prom`, // 删除新人专享活动
    goods_list: `${base}backend/TempNewer/goods_list`, // 新用户专享-商品列表
    goods_add: `${base}backend/TempNewer/goods_add`, // 新用户专享-添加商品
    goods_del: `${base}backend/TempNewer/goods_del` // 新用户专享-删除商品
  },
  // 商品类目
  Category: {
    get_cate_list: `${base}backend/Category/get_cate_list`, // 获取商品分类列表
    get_level: `${base}backend/Category/get_level`, // 获取商品分类
    add_cate: `${base}backend/Category/add_cate`, // 添加或修改后台分类
    del_cate: `${base}backend/Category/del_cate` // 删除后台分类
  },
  // 助力活动
  Assistance: {
    get_goods_sku: `${base}backend/Assistance/get_goods_sku`, // 获取商品sku
    get_goods_list: `${base}backend/Assistance/get_goods_list`, // 获取商品列表
    add_assistance: `${base}backend/assistance/add_assistance`, // 新建助力活动
    assistance_list: `${base}backend/assistance/assistance_list`, // 获取助力列表
    assistance_detail: `${base}backend/assistance/assistance_detail`, // 获取助力详情
    handle_assistance: `${base}backend/assistance/handle_assistance`, // 删除或停止助力
    del_goods_sku: `${base}backend/assistance/del_goods_sku`, // 编辑详情时移除规格
    update_assistance: `${base}backend/assistance/update_assistance` // 编辑助力详情
  },
  // 关键字维护
  word: {
    add_word: `${base}backend/word/add_word` // 添加搜索关键词
  },
  Deduction: {
    add_deduction: `${base}backend/Deduction/add_deduction`, // 新增扣款单
    deduc_get_list: `${base}backend/purchase/deduc_get_list`, // 扣款单获取采购单列表
    get_order_goods: `${base}backend/purchase/get_order_goods`, // 扣款单根据采购单获取商品列表
    deduction_list: `${base}backend/Deduction/deduction_list`, // 扣款单列表
    deduction_detail: `${base}backend/Deduction/deduction_detail`, // 扣款单详情
    undo_deduction: `${base}backend/Deduction/undo_deduction`, // 撤销扣款单
    edit_deduction: `${base}backend/Deduction/edit_deduction` // 编辑扣款单
  },
  appraise: {
    tag_list: `${base}backend/Comment/tag_list`, // 评价标签列表
    get_goods_list: `${base}backend/Comment/get_goods_list`, // 获取商品列表
    add_tag: `${base}backend/Comment/add_tag`, // 添加商品评价标签
    del_tag: `${base}backend/Comment/del_tag`, // 删除评价标签
    tag_detail: `${base}backend/Comment/tag_detail`, // 获取评价标签详情
    edit_tag: `${base}backend/Comment/edit_tag`, // 编辑标签
    comment_list: `${base}backend/comment/comment_list`, // 商品评价列表
    comment_detail: `${base}backend/comment/comment_detail`, // 评论详情
    check_comment: `${base}backend/comment/check_comment`, // 审核评价
    reply_comment: `${base}backend/comment/reply` // 供应商回复评价
  },
  suggest: {
    tag_list: `${base}backend/Feedback/tag_list`, // 反馈标签列表
    tag_del: `${base}backend/Feedback/tag_del`, // 删除反馈标签
    tag_edit: `${base}backend/Feedback/tag_edit`, // 编辑反馈标签
    tag_add: `${base}backend/feedback/tag_add`, // 新增反馈标签
    feedback_list: `${base}backend/feedback/feedback_list`, // 反馈列表
    feedback_deal: `${base}backend/Feedback/feedback_deal` // 处理反馈
  },
  debitnote: {
    deduction_list: `${base}backend/Deduction/deduction_list`, // 扣款单列表
    undo_deduction: `${base}backend/Deduction/undo_deduction` // 撤销扣款单
  },
  // 供应商一系列操作    规则管理 、  公示 、 违规
  supplier_rule: {
    list: `${base}backend/SupplierManage/rule_list`,
    edit: `${base}backend/SupplierManage/edit_rule`,
    add: `${base}backend/SupplierManage/add_rule`,
    del: `${base}backend/SupplierManage/del_rule`,
    detail: `${base}backend/SupplierManage/rule_detail`,
    publish: `${base}backend/SupplierManage/publish_rule`,
    undo: `${base}backend/SupplierManage/undo_rule`
  },
  get_out_of_line: {
    list: `${base}backend/SupplierManage/violation_list`,
    check: `${base}backend/SupplierManage/check_violation`,
    detail: `${base}backend/SupplierManage/violation_detail`,
    add: `${base}backend/SupplierManage/add_violation`,
    get_supplier: `${base}backend/SupplierManage/get_supplier`
  },
  supplier_manage: {
    list: `${base}backend/SupplierManage/supplier_manage_list`, // 供应商经营管理列表
    handle: `${base}backend/SupplierManage/deal_supplier` // 解冻冻结操作
  },
  class_manage: {
    list: `${base}backend/ScmGoods/cate_list`, // 类目列表
    del: `${base}backend/ScmGoods/del_cate`, // 删除货品分类
    add: `${base}backend/ScmGoods/add_cate`, // 新增货品分类
    edit: `${base}backend/ScmGoods/edit_cate` // 编辑货品分类
  },
  product_manage: {
    goods_list: `${base}backend/ScmGoods/goods_list`, // 获取货品列表
    channel_list: `${base}backend/ScmGoods/channel_list`, // 获取渠道列表
    get_level: `${base}backend/ScmGoods/get_level`, // 获取分类
    add_goods: `${base}backend/ScmGoods/add_goods`, // 新建货品
    edit_goods: `${base}backend/ScmGoods/edit_goods`, // 编辑货品
    goods_detail: `${base}backend/ScmGoods/goods_detail`, // 货品详情
    supplier_list: `${base}backend/ScmSupplier/get_supplier_list`, // 下拉获取供应商列表
    export_goods: `${base}backend/ScmGoods/export_goods` // 导出货品列表
  },
  // 供应链 接口
  supply: {
    supplier: {
      add: `${base}backend/ScmSupplier/add_supplier`,
      edit: `${base}backend/ScmSupplier/edit_supplier`,
      list: `${base}backend/ScmSupplier/supplier_list`,
      detail: `${base}backend/ScmSupplier/supplier_detail`,
      lock: `${base}backend/ScmSupplier/forbid_supplier`
    }
  },
  get_address: `${base}backend/ScmSupplier/get_province_list`,
  ScmChannel: {
    channel_list: `${base}backend/ScmChannel/channel_list`, // 渠道列表
    add_channel: `${base}backend/ScmChannel/add_channel`, // 新增渠道
    edit_channel: `${base}backend/ScmChannel/edit_channel`, // 编辑渠道
    channel_detail: `${base}backend/ScmChannel/channel_detail`, // 渠道详情
    forbid_channel: `${base}backend/ScmChannel/forbid_channel` // 禁用/解禁渠道
  },
  ScmOrder: {
    order_list: `${base}backend/ScmOrder/order_list`, // 订单列表
    import_order: `${base}backend/ScmOrder/import_order`, // 导入订单
    export_template: `${base}backend/ScmOrder/export_template`, // 导出订单模板
    export_fail: `${base}backend/ScmOrder/export_fail`, // 导出失败订单
    order_detail: `${base}backend/ScmOrder/order_detail`, // 订单详情
    cancel_order: `${base}backend/ScmOrder/cancel_order`, // 取消订单
    export_shipped: `${base}backend/ScmOrder/export_shipped`, // 导出已发货订单列表
    ScmPurchase: `${base}backend/ScmOrder/ScmPurchase` // 导出失败发货单
  },
  ScmPurchase: {
    purchase_list: `${base}backend/ScmPurchase/purchase_list`, // 发货单列表
    purchase_detail: `${base}backend/ScmPurchase/purchase_detail`, // 发货单详情
    cancel_purchase: `${base}backend/ScmPurchase/cancel_purchase`, // 取消发货单
    export_template: `${base}backend/ScmPurchase/export_template`, // 待发货导出
    import_purchase: `${base}backend/ScmPurchase/import_purchase` // 导入发货单
  },
  ScmGoods: {
    channel_list: `${base}backend/ScmGoods/channel_list` // 获取渠道列表
  },
  combo: {
    combo_list: `${base}backend/combo/combo_list`, // 套餐--列表
    combo_create: `${base}backend/combo/combo_create`, // 套餐--创建
    combo_update: `${base}backend/combo/combo_update`, // 套餐--编辑
    combo_detail: `${base}backend/combo/combo_detail`, // 套餐--详情
    combo_del: `${base}backend/combo/combo_del` // 套餐--删除
  },
  GoodsStat: {
    goods_flow: `${base}backend/GoodsStat/goods_flow`, // 获取商品总览和趋势
    goods_rank: `${base}backend/GoodsStat/goods_rank`, // 商品TOP榜
    single_goods_data: `${base}backend/GoodsStat/single_goods_data` // 单商品分析
  },
  ipa: {
    org_list: `${base}backend/TasteOrg/org_list`, // 组织列表
    goods_list: `${base}backend/TasteOrg/goods_list`, // 组织已经关联的商品
    del_goods: `${base}backend/TasteOrg/del_goods`, // 删除组织商品
    get_goods_list: `${base}backend/TasteOrg/get_goods_list`, // 未选的商品
    address_list: `${base}backend/TasteOrg/address_list`, // 获取可选的自提点列表
    add_goods: `${base}backend/TasteOrg/add_goods`, // 增加组织商品
    user_list: `${base}backend/TasteUser/user_list`, // 用户列表
    del_org_user: `${base}backend/TasteUser/del_org_user`, // 删除用户
    add_user: `${base}backend/TasteUser/add_user`, // 手动增加内购用户
    import_user: `${base}backend/TasteUser/import_user`, // 导入用户
    balance_list: `${base}backend/TasteUser/balance_list`, // 内购余额列表
    balance_detail: `${base}backend/TasteUser/balance_detail`, // 余额明细
    edit_sort: `${base}backend/TasteOrg/edit_sort`, // 组织商品编辑
    check_before_import: `${base}backend/TasteUser/check_before_import`, // 检查导入的列表
    forbidden_user: `${base}backend/TasteUser/forbidden_user`, // 禁用启用
    deduct_balance: `${base}backend/TasteUser/deduct_balance` // 扣除余额接口
  },
  // 积分
  ScoreTask: {
    task_list: `${base}backend/ScoreTask/task_list`, // 积分任务列表
    task_detail: `${base}backend/ScoreTask/task_detail`, // 编辑前获取任务详情
    update_task: `${base}backend/ScoreTask/update_task`, // 编辑积分任务
    score_detail: `${base}backend/ScoreTask/score_detail`, // 任务列表明细
    open_close_score: `${base}backend/ScoreTask/open_close_score` // 积分活动开关
  },
  // 积分
  Score: {
    exchange_list: `${base}backend/Score/exchange_list`, // 兑换列表
    exchange_record: `${base}backend/Score/exchange_record`, // 兑换列表-查看明细
    del_exchange: `${base}backend/Score/del_exchange`, // 删除兑换
    user_score_list: `${base}backend/Score/user_score_list`, // 会员积分列表
    user_score_detail: `${base}backend/Score/user_score_detail` // 会员积分明细
  },
  // 积分统计
  ScoreStat: {
    overview: `${base}backend/ScoreStat/overview`, // 积分数据总览
    tendency: `${base}backend/ScoreStat/tendency` // 积分数据折线图
  },
  // 抽奖统计
  LotteryStat: {
    overview: `${base}backend/LotteryStat/overview`, // 抽奖数据总览
    tendency: `${base}backend/LotteryStat/tendency` // 抽奖数据折线图
  },
  // 抽奖
  Lottery: {
    add: `${base}backend/Lottery/add`, // 新建抽奖
    lottery_list: `${base}backend/Lottery/lottery_list`, // 抽奖列表
    detail: `${base}backend/Lottery/detail`, // 抽奖活动详情
    edit: `${base}backend/Lottery/edit`, // 更新抽奖
    lottery_record: `${base}backend/Lottery/lottery_record`, // 抽奖记录
    coupon_list: `${base}backend/Lottery/coupon_list`, // 优惠券列表
    subject_list: `${base}backend/Lottery/subject_list`, // 获取话题列表
    get_goods_list: `${base}backend/Lottery/get_goods_list`, // 获取商品列表
    get_goods_sku: `${base}backend/Lottery/get_goods_sku`, // 获取商品sku
    open_close_lottery: `${base}backend/Lottery/open_close_lottery` // 抽奖活动开关
  },
  ChannelConfig: {
    channel_list: `${base}backend/Config/channel_list`, // 频道列表
    channel_save: `${base}backend/Config/channel_save`, // 频道新增与编辑
    channel_del: `${base}backend/Config/channel_del` // 频道删除
  },

  // 农博会新接口
  getStoreList: `${base}backend/site/site_list`, // 展馆列表
  getStoreEdit: `${base}backend/site/save`, // 展馆新增&编辑
  setStoreStatus: `${base}backend/site/site_set`, // 展馆排序、删除、显示
  getStoreInfo: `${base}backend/site/site_info`, // 展馆详情
  getAdvList: `${base}backend/ad/ad_list`, // 广告列表
  sortAdvList: `${base}backend/ad/ad_sort`, // 广告排序
  deleteAdvList: `${base}backend/ad/ad_delete`, // 广告删除
  getAdvEdit: `${base}backend/ad/ad_save`, // 广告新增&编辑
  getLocationList: `${base}backend/ad/position_list`, // 位置列表
  getLocationEdit: `${base}backend/ad/position_save`, // 位置新增&编辑
  deleteLocation: `${base}backend/ad/position_set`, // 位置删除
  getShopAgriculturalList: `${base}backend/StoreCircle/circle_list`, // 逛农博列表
  getShopAgriculturalStatus: `${base}backend/StoreCircle/circle_set`, // 逛农博排序、删除、显示、推荐
  getShopAgriculturalInfo: `${base}backend/StoreCircle/circle_info`, // 逛农博详情
  saveShopAgricultural: `${base}backend/StoreCircle/save`, // 逛农博新增&编辑
  getGoodsSimpleLabel: `${base}backend/GoodsListTag/tag_list`, // 商品标签
  saveGoodsSimpleLabel: `${base}backend/GoodsListTag/save`, // 商品标签新增&编辑
  deleteGoodsSimpleLabel: `${base}backend/GoodsListTag/tag_set`, // 商品标签删除
  getGoodsLabelCheckbox: `${base}backend/goods/goods_tag_list`, // 商品标签设置复选框
  saveGoodsLabelCheckbox: `${base}backend/goods/set_goods_list_tag`, // 商品标签设置复选框保存
  enter_nombo: `${base}backend/AppEnter/apply_list`, // 入驻农博
  saveStoreInfo: `${base}backend/store/save`, // 供应商店铺新增和编辑
  saveActPercent: `${base}backend/goods/update_prom_percent`, // 修改百分比
  deleteGoodsItem: `${base}backend/goods/goods_delete`, // 删除商品
  deleteOrdersItem: `${base}backend/order/order_delete`, // 删除订单
  previewInfo: `${base}backend/goods/goods_preview`, // 商品预览
  cancelChecked: `${base}backend/GoodsAudit/audit_cancel`, // 撤销审核
  // 农博会2.2拼团
  getGroupList: `${base}backend/Group/lists`, // 拼团商品列表
  setGroupSort: `${base}backend/Group/sort`, // 设置列表排序
  getGroupDetail: `${base}backend/Group/detail`, // 拼团详情接口
  getGroupDetailStatistics: `${base}backend/Group/suppliers_group_stat`, // 商家活动统计-查询商家活动统计基本信息
  updateGroupDetailStatistics: `${base}backend/Group/refresh_group_stat`, // 商家活动统计-刷新活动统计，远程调用服务
  terminationGroup: `${base}backend/Group/termination`, // 拼团商品终止
  deleteGroup: `${base}backend/Group/delete_group`, // 商家拼团活动删除
  getSearchGroupGoods: `${base}backend/Group/search_goods`, // 添加拼团商品 - 商品搜索
  addGroupGoodActive: `${base}backend/Group/add`, // 添加拼团商品

  // 商品规格
  GoodsSpec: {
    spec_item_save: `${base}backend/GoodsSpec/spec_item_save`, // 规格项 新增或编辑
    spec_save: `${base}backend/GoodsSpec/spec_save`, // 规格 新增与编辑
    spec_delete: `${base}backend/GoodsSpec/spec_delete`, // 删除规格
    spec_item_delete: `${base}backend/GoodsSpec/spec_item_delete`, // 删除规格项
    get_spec: `${base}backend/GoodsSpec/get_spec` // 获取商品规格
  },
  GoodsSku: {
    sku_save: `${base}backend/GoodsSku/sku_save`, // SKU新增或编辑
    get_sku: `${base}backend/GoodsSku/get_sku` // 获取sku数据
  },
  GoodsAudit: {
    audit_goods_list: `${base}backend/GoodsAudit/audit_goods_list`, // 商品审核列表
    get_audit_record: `${base}backend/GoodsAudit/get_audit_record`, // 历史审核记录
    submit: `${base}backend/GoodsAudit/submit`, // 商品提交审核
    set_allow_status: `${base}backend/GoodsAudit/set_allow_status`, // 审核：通过，拒绝
    get_audit_record_goods_info: `${base}backend/GoodsAudit/get_audit_record_goods_info` // 历史审核的商品详情
  },
  HealthCode: {
    get_list: `${base}backend/HealthCode/get_list`, // 获取健康码列表
    add: `${base}backend/HealthCode/add`, // 添加供应商
    get_info: `${base}backend/HealthCode/get_info`, // 获取健康码信息
    update: `${base}backend/HealthCode/update`, // 更新健康码
    delete: `${base}backend/HealthCode/delete`, // 删除用户
    supplier_health_code_list: `${base}backend/HealthCode/supplier_health_code_list` // 获取供应商下的配送人员健康码
  },
  Address: {
    get_address_list: `${base}/backend/address/get_address_list`, // 获取地址列表
    get_third_address: `${base}backend/address/get_third_address`, // 获取所有地址
    get_four_address: `${base}backend/address/get_four_address`
  },

  // 运费模板
  Freight: {
    get_list: `${base}backend/freight/template_list`, // 获取运费模板列表
    git_rule_list: `${base}backend/freight/template_info`, // 运费模板详情
    add_save: `${base}backend/freight/save`, // 新增或编辑
    delete_model: `${base}backend/freight/template_del`, // 删除运费模板
    delete_rule: `${base}backend/freight/rule_delete` // 删除规则
  },

  // 直播
  live: {
    liveActivityList: `${base}backend/LiveActivitySuppliers/lists`, // 直播活动列表
    liveActivityOperate: `${base}backend/LiveActivitySuppliers/shelf`, // 直播活动上下架
    liveActivityDel: `${base}backend/LiveActivitySuppliers/softdelete`, // 删除直播活动
    activityRecommend: `${base}backend/LiveActivitySuppliers/recommend`, // 设置直播活动推荐
    activityDetail: `${base}backend/LiveActivitySuppliers/show`, // 直播活动基本详情
    liveActorList: `${base}backend/LiveActor/live_actor_list`, // 主播账号id和昵称信息列表
    liveActivityEdit: `${base}backend/LiveActivitySuppliers/edit`, // 编辑直播活动
    liveActivityAdd: `${base}backend/LiveActivitySuppliers/add`, // 添加直播活动
    liveActivityGoodsMenu: `${base}backend/LiveActivitySuppliers/live_ready_goods_lists`, // 供直播间选择商品的的商品列表（含公共池和私人池）
    liveActivityGoodsList: `${base}backend/LiveActivitySuppliers/live_goods`, // 直播间商品列表
    pushActivityGoods: `${base}backend/LiveActivitySuppliers/push_goods_to_live`, // 添加商品池的商品到 直播间
    liveActivityGoodsDel: `${base}backend/LiveActivitySuppliers/cancel_live_goods` // 商品取消参加直播间活动
  },
  LiveActivity: {
    statistics: `${base}backend/LiveActivity/statistics`, // 单场直播的数据统计，（销量数据汇总和商品列表销售数据），不含时间节点折线图数据
    zigzag: `${base}backend/LiveActivity/zigzag` // 以时间节点的直播数据统计 （直播数据折线图）
  },
  // 直播
  LiveOnline: {
    good_pond_list: `${base}backend/LivePond/lists`, // 直播商品池列表
    good_pond_edit: `${base}backend/LivePond/edit`, // 直播商品池编辑
    good_pond_add: `${base}backend/LivePond/add`, // 直播商品池新增
    good_pond_del: `${base}backend/LivePond/del`, // 直播商品池删除
    good_pond_detail: `${base}backend/LiveGoodsBankSuppliers/getGoodsBankList`, // 商品池详情列表
    good_anchor_list: `${base}backend/LiveActor/live_actor_list`, // 主播列表
    good_cancel: `${base}backend/LiveGoodsBankSuppliers/deleteGoodsBank`, // 取消商品参与活动
    get_goods_list: `${base}backend/LiveGoodsBankSuppliers/getSuppliersGoodsList`, // 获取商品列表
    add_goods_item: `${base}backend/LiveGoodsBankSuppliers/addGoodsBank`, // 添加商品到商品池
    goods_spec_list: `${base}backend/LiveGoodsBankSuppliers/showSpec`, // 获取商品规格
    edit_spec_item: `${base}backend/LiveGoodsBankSuppliers/editSpec` // 直播商品更改信息
  },
  comment: {
    cancelTopComment: `${base}backend/comment/cancel_top_comment`, // 评论取消置顶
    saveCommentTop: `${base}backend/comment/is_top_comment` // 评价置顶
  },
  LiveGoodsBankSuppliers: {
    getSuppliersGoodsList: `${base}backend/LiveGoodsBankSuppliers/getSuppliersGoodsList`,
    addGoodsBank: `${base}backend/LiveGoodsBankSuppliers/addGoodsBank`,
    deleteGoodsBank: `${base}backend/LiveGoodsBankSuppliers/deleteGoodsBank`,
    showSpec: `${base}backend/LiveGoodsBankSuppliers/showSpec`, // 商品库列表 -- 获取规格价格
    editSpec: `${base}backend/LiveGoodsBankSuppliers/editSpec`, // 商品库列表 -- 直播改价
    getGoodsBankList: `${base}backend/LiveGoodsBankSuppliers/getGoodsBankList` // 商品库列表
  },
  LivePondSuppliers: {
    getLiveGoodsList: `${base}backend/LivePondSuppliers/getLiveGoodsList`, // 直播商品列表
    getReadyGoodsList: `${base}backend/LivePondSuppliers/getReadyGoodsList`, // 直播商品列表 -- 添加商品列表
    pushGoodsToLive: `${base}backend/LivePondSuppliers/pushGoodsToLive`, // 直播商品列表 -- 确认添加
    cancelLiveGoods: `${base}backend/LivePondSuppliers/cancelLiveGoods`, // 直播商品列表 -- 删除
    setTop: `${base}backend/LivePondSuppliers/setTop`, // 直播商品列表 -- 置顶
    cancelTop: `${base}backend/LivePondSuppliers/cancelTop` // 直播商品列表 -- 取消置顶
  },
  LiveActivitySuppliers: {
    statistics: `${base}backend/LiveActivitySuppliers/statistics`, // 单场直播的数据统计，（销量数据汇总和商品列表销售数据），不含时间节点折线图数据
    zigzag: `${base}backend/LiveActivitySuppliers/zigzag` // 以时间节点的直播数据统计 （直播数据折线图）
  },
  // 帮助中心
  HelpCenter: {
    pageData: `${base}backend/ConfigHelp/getConfigHelpGroupList`
  },
  // 营销活动
  marketAct: { ...marketApi },
  // 供应商店铺/商品类型
  getSupplierType: {
    getType: `${base}backend/suppliers/get_supplier_type`,
    goodsSet: `${base}backend/Suppliers/checkSuppliersSetGoodsHotelRule`
  },
  // 自提管理
  selfRaise: {
    list: `${base}backend/submitaddress/getlist`,
    add: `${base}backend/submitaddress/add`,
    edit: `${base}backend/submitaddress/edit`,
    get_detail: `${base}backend/submitaddress/getrow`,
    del: `${base}backend/submitaddress/batchdel`,
    codeDeliver: `${base}backend/purchase/codedeliver`
  },
  // 店铺商品设置
  storeGoodsSet: { ...storeGoodsSetApi },

  // 新版农博
  ...userApis,
  ...adminApis,
  ...storeApis,
  ...goodsApis,
  ...orderApis,
  ...settingApis,
  ...supplierApis,
  ...scmApis,
  ...commentApis,
  ...manageApis,
  ...marketingApis
}
export default Api
