import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(Router)

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

export default new Router({
  // mode: 'history',  // Enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  base: '/', // process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: () => import('@/views/login/index.vue'),
      meta: {
        title: '登录',
        hidden: true
      }
    },
    {
      path: '/404',
      component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
      meta: { hidden: true }
    },
    {
      path: '/',
      component: Layout,
      meta: { title: '店铺' },
      redirect: '/store/dashboard',
      children: [
        {
          path: '/store/dashboard',
          component: () => import('@/views/store/dashboard.vue'),
          meta: {
            title: '店铺概览'
          }
        },
        {
          path: '/store/message/list',
          component: () => import('@/views/store/message.vue'),
          meta: {
            title: '消息通知'
          }
        },
        {
          path: '/store/qrCode',
          component: () => import('@/views/store/qrCode.vue'),
          meta: {
            title: '关联微信'
          }
        },
        {
          path: '/store/wxUser',
          component: () => import('@/views/store/wxUser.vue'),
          meta: {
            title: '消息通知'
          }
        },
        {
          path: '/store/supplier',
          component: () => import('@/views/store/supplier.vue'),
          meta: {
            title: '供应商信息'
          }
        },
        {
          path: '/store/editSupplier',
          component: () => import('@/views/store/editSupplier.vue'),
          meta: {
            title: '编辑供应商'
          }
        },
        {
          path: '/store/editStore',
          component: () => import('@/views/store/editStore.vue'),
          meta: {
            title: '店铺信息'
          }
        },
        {
          path: '/store/tradests',
          component: () => import('@/views/store/tradeSts.vue'),
          meta: {
            title: '店铺交易数据'
          }
        },
        {
          path: '/store/help',
          component: () => import('@/views/help/index.vue'),
          meta: {
            title: '帮助中心'
          }
        },
        {
          path: '/store/decorate',
          component: () => import('@/views/store/decorate/index.vue'),
          meta: {
            title: '店铺装修'
          }
        },
        {
          path: '/subject',
          component: () => import('@/views/subject/index.vue'),
          meta: {
            title: '专题模板'
          }
        },
        {
          path: '/operation/subject/detail',
          component: (): any => import('@/views/subject/detail/index.vue'),
          meta: {
            title: '专题模板详情'
          }
        },
        {
          path: '/rule/list',
          component: () => import('@/views/rule/list.vue'),
          meta: {
            title: '规则中心'
          }
        },
        {
          path: '/store/goodsData',
          component: () => import('@/views/store/goodsData/index.vue'),
          meta: {
            title: '店铺商品设置'
          }
        },
        {
          path: '/store/warn',
          component: () => import('@/views/store/warn/index.vue'),
          meta: {
            title: '店铺预警'
          }
        },
        {
          path: '/store/complaint/list',
          component: () => import('@/views/store/complaint.vue'),
          meta: {
            title: '用户投诉'
          }
        }
      ]
    },
    {
      path: '',
      component: Layout,
      meta: { title: '财务' },
      children: [
        {
          path: '/finance/settlement',
          component: () => import('@/views/finance/settlementList.vue'),
          meta: {
            title: '结算单列表'
          }
        },
        {
          path: '/finance/settlementdetail',
          component: () => import('@/views/finance/settlementDetail.vue'),
          meta: {
            title: '结算单详情'
          }
        },
        {
          path: '/finance/supplybank',
          component: () => import('@/views/finance/supplybank.vue'),
          meta: {
            title: '收款信息填写'
          }
        },
        {
          path: '/finance/nongxin/settlement',
          component: () => import('@/views/finance/nongxin/settlementList.vue'),
          meta: {
            hidden: true,
            title: '农信结算单'
          }
        },
        {
          path: '/finance/SAW',
          component: () => import('@/views/finance/SAW.vue'),
          meta: {
            title: '结算与提现'
          }
        },
        {
          path: '/finance/closeDetail',
          component: () => import('@/views/finance/closeDetail.vue'),
          meta: {
            title: '结算单详情'
          }
        }
      ]
    },
    {
      path: '',
      component: Layout,
      meta: {
        title: '人员管理',
        icon: 'iconshangpinx'
      },
      children: [
        {
          path: '/employ/express/list',
          component: () =>
            import(
              /* webpackChunkName: "coupon" */ '@/views/employ/express/index.vue'
            ),
          meta: {
            title: '同城配送人员列表'
          }
        },
        {
          path: '/employ/express/detail',
          component: () =>
            import(
              /* webpackChunkName: "coupon" */ '@/views/employ/express/detail.vue'
            ),
          meta: {
            title: '同城配送人员详情'
          }
        }
      ]
    },
    {
      path: '',
      component: Layout,
      meta: { title: '物流管理' },
      children: [
        {
          path: '/freight/list',
          component: () => import('@/views/freight/list.vue'),
          meta: {
            title: '运费模板列表'
          }
        },
        {
          path: '/freight/add',
          component: () => import('@/views/freight/add.vue'),
          meta: {
            title: '新建物流运费模板'
          }
        },
        {
          path: '/freight/city',
          component: () => import('@/views/freight/city.vue'),
          meta: {
            title: '新建同城配运费模板'
          }
        },
        {
          path: '/selfRaise/list',
          component: () => import('@/views/selfRaise/index.vue'),
          meta: {
            title: '配送管理'
          }
        },
        {
          path: '/selfRaise/detail',
          component: () => import('@/views/selfRaise/detail.vue'),
          meta: {
            title: '上门自提'
          }
        },
        {
          path: '/selfRaise/jdong',
          name: 'toBeShipped',
          component: () => import('@/views/selfRaise/jdong.vue'),
          meta: {
            title: '京东发货'
          }
        }
      ]
    },
    // 新版农博
    {
      path: '',
      component: Layout,
      meta: { title: '商品' },
      children: [
        // 商品管理
        {
          path: '/nb/goods/index',
          component: () => import('@/views/nb/goods/goods/index.vue'),
          meta: {
            title: '商品列表',
            icon: 'iconcaiwurenminbizhifux',
            keepAlive: true
          }
        },
        {
          path: '/nb/goods/add',
          component: () => import('@/views/nb/goods/goods/add.vue'),
          meta: {
            title: '新增商品',
            icon: 'iconcaiwurenminbizhifux'
          }
        },
        {
          path: '/nb/goods/detail',
          component: () => import('@/views/nb/goods/goods/detail.vue'),
          meta: {
            title: '商品详情',
            icon: 'iconcaiwurenminbizhifux'
          }
        },
        {
          path: '/nb/goods/homeSet',
          component: () => import('@/views/nb/goods/goods/homeSet/index.vue'),
          meta: {
            title: '房源管理',
            icon: 'iconcaiwurenminbizhifux'
          }
        },
        {
          path: '/nb/goods/equityCardSet',
          component: () =>
            import('@/views/nb/goods/goods/equityCardSet/index.vue'),
          meta: {
            title: '权益卡设置',
            icon: 'iconcaiwurenminbizhifux'
          }
        },
        {
          path: '/nb/goods/pickCardSet',
          component: () => import('@/views/nb/goods/goods/pickCardSet/index.vue'),
          meta: {
            title: '景点/采摘日票设置',
            icon: 'iconcaiwurenminbizhifux'
          }
        },
        {
          path: '/nb/goods/freight',
          component: () => import('@/views/nb/goods/freight/index.vue'),
          meta: {
            title: '运费模板管理'
          }
        },
        {
          path: '/nb/goods/freight/common',
          component: () => import('@/views/nb/goods/freight/common.vue'),
          meta: {
            title: '普通快递'
          }
        },
        {
          path: '/nb/goods/freight/city',
          component: () => import('@/views/nb/goods/freight/city.vue'),
          meta: {
            title: '同城配送'
          }
        },
        {
          path: '/nb/goods/freight/self',
          component: () => import('@/views/nb/goods/freight/self.vue'),
          meta: {
            title: '自提'
          }
        },
        {
          path: '/nb/goods/changePrice/list',
          component: () => import('@/views/nb/goods/changePrice/list.vue'),
          meta: {
            title: '改价记录'
          }
        },
        {
          path: '/goods/eval',
          component: () => import('@/views/goods/eval/index.vue'),
          meta: {
            title: '评价管理'
          }
        }
      ]
    },
    {
      path: '',
      component: Layout,
      meta: { title: '门店管理', icon: 'icondingdanx' },
      children: [
        {
          path: '/nb/offlineStore/list',
          component: () => import('@/views/nb/goods/offlineStore/list.vue'),
          meta: {
            title: '门店管理'
          }
        },
        {
          path: '/nb/offlineStore/detail',
          component: () => import('@/views/nb/goods/offlineStore/detail.vue'),
          meta: {
            title: '门店管理'
          }
        }
      ]
    },
    {
      path: '',
      component: Layout,
      meta: { title: '营销推广' },
      children: [
        {
          path: '/nb/marketingTools/orderBackCoupon',
          component: () =>
            import('@/views/nb/marketingTools/orderBackCoupon/index.vue'),
          meta: {
            title: '下单返券'
          }
        },
        {
          path: '/nb/marketingTools/orderBackCoupon/detail',
          component: () =>
            import('@/views/nb/marketingTools/orderBackCoupon/detail.vue'),
          meta: {
            title: '详情'
          }
        },
        {
          path: '/nb/marketingTools/activity/screen',
          component: () =>
            import('@/views/nb/marketingTools/activity/screen.vue'),
          meta: {
            title: '活动报名列表'
          }
        },
        {
          path: '/nb/marketingTools/activity/list',
          component: () =>
            import('@/views/nb/marketingTools/activity/list.vue'),
          meta: {
            title: '营销活动'
          }
        },
        {
          path: '/nb/marketingTools/activity/detail',
          component: () =>
            import('@/views/nb/marketingTools/activity/detail.vue'),
          meta: {
            title: '活动详情'
          }
        },
        {
          path: '/nb/marketingTools/activity/apply',
          component: () =>
            import('@/views/nb/marketingTools/activity/apply.vue'),
          meta: {
            title: '活动商品'
          }
        },
        {
          path: '/nb/marketingTools/exchange',
          component: () =>
            import('@/views/nb/marketingTools/exchange/index.vue'),
          meta: {
            title: '加价换购'
          }
        },
        {
          path: '/nb/marketingTools/exchange/edit',
          component: () =>
            import('@/views/nb/marketingTools/exchange/edit.vue'),
          meta: {
            title: '_'
          }
        },
        {
          path: '/nb/marketingTools/communityGroup',
          component: () =>
            import('@/views/nb/marketingTools/communityGroup/index.vue'),
          meta: {
            title: '社区团购'
          }
        },
        {
          path: '/nb/marketingTools/coupon/index',
          component: () => import('@/views/nb/marketingTools/coupon/index.vue'),
          meta: {
            title: '优惠券'
          }
        },
        {
          path: '/nb/marketingTools/coupon/addAndEdit',
          component: () =>
            import(
              '@/views/nb/marketingTools/coupon/components/addAndEdit.vue'
            ),
          meta: {
            title: '优惠券详情'
          }
        },
        {
          path: '/nb/marketingTools/live',
          component: () => import('@/views/nb/marketingTools/live/index.vue'),
          meta: {
            title: '直播'
          }
        },
        {
          path: '/nb/marketingTools/live/addAndEdit',
          component: () =>
            import('@/views/nb/marketingTools/live/activity/addAndEdit.vue'),
          meta: {
            title: '直播详情'
          }
        },
        {
          path: '/nb/marketingTools/live/control',
          component: () =>
            import('@/views/nb/marketingTools/live/activity/control.vue'),
          meta: {
            title: '直播管理'
          }
        },
        {
          path: '/nb/marketingTools/group',
          component: () => import('@/views/nb/marketingTools/group/index.vue'),
          meta: {
            title: '拼团'
          }
        },
        {
          path: '/nb/marketingTools/group/detail',
          component: () =>
            import('@/views/nb/marketingTools/group/detail/index.vue'),
          meta: {
            title: '拼团详情'
          }
        },
        {
          path: '/nb/marketingTools/nombo/index',
          component: () => import('@/views/nb/marketingTools/nombo/index.vue'),
          meta: {
            title: '农民说动态管理'
          }
        },
        {
          path: '/nb/marketingTools/nombo/detail',
          component: () =>
            import('@/views/nb/marketingTools/nombo/detail/index.vue'),
          meta: {
            title: '农民说详情'
          }
        },
        {
          path: '/nb/commission/list',
          component: () =>
            import(
              '@/views/nb/marketingTools/distribution/commission/list.vue'
            ),
          meta: {
            title: '佣金明细'
          }
        },
        {
          path: '/nb/distribution/setting',
          component: () =>
            import(
              '@/views/nb/marketingTools/distribution/setting/setting.vue'
            ),
          meta: {
            title: '分销设置'
          }
        },
        {
          path: '/nb/distribution/goodsSetting',
          component: () =>
            import(
              '@/views/nb/marketingTools/distribution/setting/goodsSetting.vue'
            ),
          meta: {
            title: '分销商品设置'
          }
        },
        {
          path: '/distribution/commission/log',
          component: () =>
            import('@/views/nb/marketingTools/distribution/commissionLog.vue'),
          meta: {
            title: '分销日志'
          }
        },
        {
          path: '/nb/distribution/addGoods',
          component: () =>
            import(
              '@/views/nb/marketingTools/distribution/setting/addGoods.vue'
            ),
          meta: {
            title: '添加分销商品'
          }
        }
      ]
    },
    {
      path: '',
      component: Layout,
      meta: { title: '商家管理' },
      children: [
        {
          path: '/supplier/brandAuth',
          component: () => import('@/views/nb/supplier/brand-auth/index.vue'),
          meta: {
            title: '品牌审核'
          }
        },
        {
          path: '/supplier/brandAuth/detail',
          component: () => import('@/views/nb/supplier/brand-auth/detail.vue'),
          meta: {
            title: '品牌详情'
          }
        },
        {
          path: '/nb/order/addressManagement/list',
          component: () =>
            import('@/views/nb/setting/addressManagement/index.vue'),
          meta: {
            title: '地址库管理'
          }
        }
      ]
    },
    {
      path: '',
      component: Layout,
      meta: { title: '设置' },
      children: [
        {
          path: '/nb/setting/account/list',
          component: () => import('@/views/nb/setting/account/list.vue'),
          meta: {
            title: '账号管理'
          }
        },
        {
          path: '/nb/setting/account/detail',
          component: () => import('@/views/nb/setting/account/detail.vue'),
          meta: {
            title: '账号详情'
          }
        },
        {
          path: '/nb/setting/account/info',
          component: () => import('@/views/nb/setting/account/accountInfo.vue'),
          meta: {
            title: '账号信息'
          }
        },
        {
          path: '/nb/setting/account/mobile/edit',
          component: () => import('@/views/nb/setting/account/mobileEdit.vue'),
          meta: {
            title: '账号信息'
          }
        },
        {
          path: '/nb/setting/role/list',
          component: () => import('@/views/nb/setting/role/list.vue'),
          meta: {
            title: '角色列表'
          }
        },
        {
          path: '/user/role/detail',
          component: () => import('@/views/nb/setting/role/detail.vue'),
          meta: {
            title: '角色详情'
          }
        },
        {
          path: '/nb/setting/merchants/list',
          component: () =>
            import('@/views/nb/setting/merchants/merchantsList.vue'),
          meta: {
            title: '商家列表'
          }
        },
        {
          path: '/nb/setting/merchants/detail',
          component: () => import('@/views/shopSettle/info.vue'),
          meta: {
            title: '商家详情'
          }
        },
        {
          path: '/nb/setting/store/list',
          component: () => import('@/views/nb/setting/store/storeList.vue'),
          meta: {
            title: '店铺列表'
          }
        },
        {
          path: '/nb/setting/store/detail',
          component: () => import('@/views/nb/setting/store/storeDetail.vue'),
          meta: {
            title: '店铺详情'
          }
        },
        {
          path: '/nb/setting/menu',
          component: () => import('@/views/nb/setting/menu/index.vue'),
          meta: {
            title: '导航管理'
          }
        }
      ]
    },
    {
      path: '',
      component: Layout,
      meta: { title: '订单管理', icon: 'icondingdanx' },
      children: [
        {
          path: '/nb/order/list',
          component: () => import('@/views/nb/order/orderList.vue'),
          meta: {
            title: '订单查询'
          }
        },
        {
          path: '/nb/order/address',
          component: () => import('@/views/nb/order/address/index.vue'),
          meta: {
            title: '改地址申请'
          }
        },
        {
          path: '/nb/order/detail',
          component: () => import('@/views/nb/order/orderDetail.vue'),
          meta: {
            title: '订单详情'
          }
        },
        {
          path: '/nb/order/homestay/detail',
          component: () => import('@/views/nb/order/homestayDetail.vue'),
          meta: {
            title: '订单详情'
          }
        },
        {
          path: '/nb/order/delivery/list',
          component: () => import('@/views/nb/order/delivery/list.vue'),
          meta: {
            title: '发货中心'
          }
        },
        {
          path: '/nb/order/refund/list',
          component: () => import('@/views/nb/order/refund/refundList.vue'),
          meta: {
            title: '商品售后'
          }
        },
        {
          path: '/nb/order/refund/detail',
          component: () => import('@/views/nb/order/refund/refundDetail.vue'),
          meta: {
            title: '售后信息'
          }
        },
        {
          path: '/nb/order/refund/track',
          component: () => import('@/views/nb/order/refund/trackDetail.vue'),
          meta: {
            title: '物流信息'
          }
        },
        {
          path: '/nb/order/jd/print',
          component: () => import('@/views/nb/order/jd/print.vue'),
          meta: {
            title: '订单打印'
          }
        },
        {
          path: '/order/appointment',
          component: () => import('@/views/nb/order/appointment/index.vue'),
          meta: {
            title: '到店预约'
          }
        },
      ]
    },
    {
      path: '/customerService',
      component: Layout,
      meta: { title: '客服管理' },
      children: [
        {
          path: 'list',
          name: 'customerServiceList',
          component: () => import('@/views/ldx/tooth/customerServiceList.vue'),
          meta: { title: '客服列表' }
        }
      ]
    },
    {
      path: '/store',
      component: () => import('@/views/nb/setting/store/storeAudit.vue'),
      meta: { hidden: true, title: '店铺信息' }
    },
    {
      path: '/supplybankAudit',
      component: () => import('@/views/finance/supplybankAudit.vue'),
      meta: {
        hidden: true,
        title: '收款信息填写'
      }
    },
    {
      path: '/message',
      component: Layout,
      meta: { title: '消息管理' },
      redirect: '/message/list',
      children: [
        {
          path: 'list',
          name: 'MsgList',
          component: () => import('@/views/ldx/message/list.vue'),
          meta: {
            title: '消息列表'
          }
        },
        {
          path: 'detail',
          name: 'MsgDetail',
          component: () => import('@/views/ldx/message/detail.vue'),
          meta: {
            hidden: true,
            title: '消息详情'
          }
        }
      ]
    },
    {
      path: '/lookIcons',
      name: 'lookIcons',
      component: () => import('@/views/ldx/lookIcon.vue'),
      meta: { title: '图标' }
    },
    {
      path: '*',
      redirect: '/404',
      meta: { hidden: true }
    },
    {
      path: '/ZC/message/ifram',
      name: 'ZCMessageIfram',
      component: () => import('@/views/ZCGysMessage/conversationIfram.vue'),
      meta: { title: '智齿商家' }
    },
    {
      path: '',
      name: 'settle-cfnb',
      component: () => import('@/views/settle-cfnb/index.vue'),
      meta: { title: '我要开店' },
      children: [
        {
          path: '/settle/cfnb/steps',
          name: 'settleCfnbSteps',
          component: () => import('@/views/settle-cfnb/steps.vue'),
          meta: {
            signIn: true,
            title: '入驻步骤'
          }
        },
        {
          path: '/settle/cfnb/register',
          name: 'settleCfnbRegister',
          component: () => import('@/views/settle-cfnb/register.vue'),
          meta: {
            signIn: true,
            title: '开店注册'
          }
        },
        {
          path: '/settle/cfnb/info',
          name: 'settleCfnbInfo',
          component: () => import('@/views/settle-cfnb/info.vue'),
          meta: {
            signIn: true,
            title: '开店信息'
          }
        }
      ]
    },
    {
      path: '',
      name: 'shopSettle',
      component: () => import('@/views/shopSettle/index.vue'),
      meta: { title: '我要开店' },
      children: [
        {
          path: '/shopSettle/steps',
          name: 'shopSettlesteps',
          component: () => import('@/views/shopSettle/steps.vue'),
          meta: {
            signIn: true,
            title: '入驻步骤'
          }
        },
        {
          path: '/shopSettle/register',
          name: 'shopSettleRegister',
          component: () => import('@/views/shopSettle/register.vue'),
          meta: {
            signIn: true,
            title: '开店注册'
          }
        },
        {
          path: '/shopSettle/info',
          name: 'shopSettleInfo',
          component: () => import('@/views/shopSettle/info.vue'),
          meta: {
            signIn: true,
            title: '开店信息'
          }
        }
      ]
    },
    {
      path: '',
      component: Layout,
      meta: { title: '供应链', icon: 'icondingdanx' },
      children: [
        {
          path: '/supplyChain/cooperation/index',
          component: () =>
            import('@/views/nb/supplyChain/cooperation/index.vue'),
          meta: {
            title: '供应链合作'
          }
        },
        {
          path: '/supplyChain/cooperation/detail',
          component: () =>
            import('@/views/nb/supplyChain/cooperation/detail.vue'),
          meta: {
            title: '供应链合作详情'
          }
        },
        {
          path: '/supplyChain/purchaseOrder',
          component: () =>
            import('@/views/nb/supplyChain/purchaseOrder/index.vue'),
          meta: {
            title: '订货单'
          }
        },
        {
          path: '/supplyChain/purchaseOrder/detail',
          component: () =>
            import('@/views/nb/supplyChain/purchaseOrder/detail.vue'),
          meta: {
            title: '订货单详情'
          }
        },
        {
          path: '/supplyChain/returnOrder',
          component: () =>
            import('@/views/nb/supplyChain/returnOrder/index.vue'),
          meta: {
            title: '订货退货单'
          }
        },
        {
          path: '/supplyChain/returnOrder/detail',
          component: () =>
            import('@/views/nb/supplyChain/returnOrder/detail.vue'),
          meta: {
            title: '订货退货单详情'
          }
        },
        {
          path: '/supplyChain/statementAccount',
          component: () =>
            import('@/views/nb/supplyChain/statementAccount/index.vue'),
          meta: {
            title: '对账单'
          }
        },
        {
          path: '/supplyChain/statementAccount/detail',
          component: () =>
            import('@/views/nb/supplyChain/statementAccount/detail.vue'),
          meta: {
            title: '对账单详情'
          }
        },
        {
          path: '/supplyChain/settlementWithdrawal/index',
          component: () =>
            import('@/views/nb/supplyChain/settlementWithdrawal/index.vue'),
          meta: {
            title: '结算与提现'
          }
        },
        {
          path: '/supplyChain/billInvoicing/index',
          component: () =>
            import('@/views/nb/supplyChain/billInvoicing/index.vue'),
          meta: {
            title: '账单开票'
          }
        },
        {
          path: '/supplyChain/goods/index',
          component: () => import('@/views/nb/supplyChain/goods/index.vue'),
          meta: {
            title: '优选选品池商品'
          }
        },
        {
          path: '/supplyChain/goods/alreadyAssociated',
          component: () => import('@/views/nb/supplyChain/goods/alreadyAssociated.vue'),
          meta: {
            title: '已关联商品'
          }
        },
        {
          path: '/supplyChain/goods/productSelectionLog',
          component: () => import('@/views/nb/supplyChain/goods/productSelectionLog.vue'),
          meta: {
            title: '优选日志'
          }
        },
        {
          path: '/supplyChain/goods/inSupply',
          component: () => import('@/views/nb/supplyChain/goods/inSupply.vue'),
          meta: {
            title: '供货中商品'
          }
        },
        {
          path: '/supplyChain/delivery',
          component: () => import('@/views/nb/supplyChain/delivery/index.vue'),
          meta: {
            title: '发货中心',
            keepAlive: true
          }
        },
      ]
    },
    {
      path: '',
      component: Layout,
      meta: { title: '农房租赁' },
      children: [
        {
          path: '/lease/list',
          component: () => import('@/views/lease/manage.vue'),
          meta: {
            title: '房租管理'
          }
        },
        {
          path: '/lease/list',
          component: () => import('@/views/lease/manage.vue'),
          meta: {
            title: '房租管理'
          }
        },
        {
          path: '/lease/intention',
          component: () => import('@/views/lease/intention.vue'),
          meta: {
            title: '意向单'
          }
        },
        {
          path: '/lease/info',
          component: () => import('@/views/lease/info.vue'),
          meta: {
            title: '房屋详情'
          }
        },
      ]
    }
  ]
})
