// 监控http请求
import tracker from './config'
import axios from 'axios'

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // 对请求错误做些什么
  const response = error.response || {}
  const config = error.config || {}
  tracker.sendImmediate({
    type: 'httpError',
    content: {
      status: response.status,
      url: config.url,
      data: config.data,
      params: config.params,
      method: config.method,
      message: error.message
    }
  })
});