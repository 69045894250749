// 用户信息上报
import { getBrowserInfo, getPlatform, trackerSend, getUserInfo } from './utils'


/**
 * 获取用户信息
 */
async function userInfo() {
  const content = {}
  content.browser = getBrowserInfo()
  content.platform = getPlatform()
  content.browserInfo = navigator.userAgent
  content.mobile = getUserInfo().mobile
  content.userId = getUserInfo().userId
  trackerSend('userInfo', content)
}

window.addEventListener('load', userInfo)