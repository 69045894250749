var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-header" }, [
    _c("span", { staticClass: "title-name" }, [_vm._v(_vm._s(_vm.title))]),
    _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }