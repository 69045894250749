<template>
  <div class="page-header">
    <span class="title-name">{{ title }}</span>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-header {
    height: 58px;
    position: relative;
    background: #fff;
    .title-name {
      position: absolute;
      font-size: 18px;
      color: #0D0202;
      font-weight: 600;
      line-height: 58px;
      left: 33px;
    }
    .content {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
</style>
