<template>
  <div
    :class="{'search-panel': true,'disflex': flex}"
    @keyup.enter="getList"
  >
    <slot />
    <div class="button-area">
      <el-button
        v-if="showBtn"
        type="primary"
        style="min-width:90px;margin-right: 15px;"
        icon="el-icon-search"
        name="search"
        size="mini"
        plain
        @click="getList"
      >
        {{ bText }}
      </el-button>
      <slot name="button-right" />
    </div>
  </div>
</template>

<script>
// import vues from './vue-null.js'
import Vue from 'vue'
export default {
  name: 'SearchPanel',
  props: {
    bText: {
      type: String,
      default: '搜索'
    },
    showBtn: {
      type: Boolean,
      default: false
    },
    flex: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getList() {
      const vues = new Vue()
      vues.$emit('searchList', 1)
      this.$emit('getList', 1)
    }
  }
}
</script>

<style scoped lang="scss">
.search-panel{
  background: #ffffff;
  box-sizing: border-box;
  margin-bottom: 20px;
  border-radius: 5px;
}
.disflex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  &>div {
    margin: 0 20px 20px 0;
    border-radius: 4px;
    p {
      color:#3c3c3c;
      font-weight:bold;
      padding:0 0 10px 0px;
      width:130px;
      border-radius:4px;
      font-size:12px;
      margin: 0;
    }
  }
  .search-panel-margin-auto {
    margin-right: auto;
  }
}
.button-area{
  margin: 10px 20px 10px 0;
}
</style>
