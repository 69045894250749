import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import { trackerSend } from '@/monitor/utils'
import { projectConfigDetail } from '@/utils/projectConfig';
export interface ResponseData {
  code: number
  data: any
  message: string
}

let service: AxiosInstance | any
// 生产环境下
service = axios.create({
  baseURL: '',
  timeout: 50000
})

service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const projectCode = localStorage.getItem('projectCode') ?? projectConfigDetail.code;
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.common['Authorization'] = token
    }
    config.headers.common['commonParam'] = projectCode;
    config.headers.common['os'] = 'pc'
    return config
  },
  (error: any) => {
    // Do something with request error
    console.error('error:', error) // for debug
    Promise.reject(error)
  }
)

let switchToLogin;
service.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 200) {
      const rsp = response.data || {}
      // console.log(router, 'router')
      if (rsp.error === 0 || rsp.code === 0) {
        // const isRead: string | null = sessionStorage.getItem('is_read')
        // if (isRead === null) {
        //   axios({
        //     method: 'get',
        //     url: Api.admin.get_admin_info
        //   }).then(res => {
        //     const r = res.data || {}
        //     if (r.error === 0) {
        //       sessionStorage.setItem('is_read', r.data.is_read)
        //     }
        //   })
        // }
        return rsp
      } else {
        if (rsp.code === 703 || rsp.code === 8001) {
          if (switchToLogin) {
            return rsp;
          }
          router.replace({
            path: '/login',
            query: {
              redirect: (router as any).history.current.path
            }
          })
          Message({
            message: rsp.msg,
            type: 'error',
          });
          switchToLogin = true;
          setTimeout(() => {
            switchToLogin = false;
          }, 1000);
          return rsp;
        } else {
          if (rsp.error_msg) {
            Message({
              message: rsp.error_msg,
              type: 'error'
            })
          }
          const ignoreCodes = [8602, 8601]
          if (!ignoreCodes.includes(rsp.code)) {
            if (rsp.msg) {
              Message({
                message: rsp.msg,
                type: 'error'
              })
            }
          }
        }
        // if (!rsp.error_msg.includes('403') && rsp.error_code !== 'NOT_LOGIN' && !rsp.error_msg.includes('非供应商用户')) {
        //   if (rsp.error_msg) {
        //     Message({
        //       message: rsp.error_msg,
        //       type: 'error'
        //     })
        //   }
        //   if (rsp.msg) {
        //     Message({
        //       message: rsp.msg,
        //       type: 'error'
        //     })
        //   }
        // }
        return rsp
      }
    } else {
      Message({
        message: '网络异常，请重试',
        type: 'error'
      })
    }
  }, (error: any) => {
    const response = error.response || {}
    const config = error.config || {}
    trackerSend('httpError', {
      status: response.status,
      url: config.url,
      data: config.data,
      params: config.params,
      method: config.method,
      message: error.message
    })
    Promise.reject(error)
  }
)
export default service
