var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _vm.collapse
          ? _c(
              "div",
              { staticClass: "sidebar-logo-link", attrs: { to: "/" } },
              [
                _c("img", {
                  staticClass: "sidebar-logo",
                  attrs: { src: _vm.subjectObj.loginLogo },
                }),
              ]
            )
          : _c(
              "div",
              { staticClass: "sidebar-logo-link", attrs: { to: "/" } },
              [
                _c("img", {
                  staticClass: "sidebar-logo",
                  attrs: { src: _vm.subjectObj.loginLogo },
                }),
                _c("div", { staticClass: "sidebar-title" }, [
                  _c("div", { staticClass: "top" }, [
                    _vm._v(" AGRICULTURAL EXPO "),
                  ]),
                  _c("div", { staticClass: "bottom" }, [
                    _c("span", { staticClass: "left" }, [_vm._v("ONLINE")]),
                    _c("span", { staticClass: "right" }, [
                      _vm._v(_vm._s(_vm.subjectObj.name)),
                    ]),
                  ]),
                ]),
              ]
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }