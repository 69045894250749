/*
 * @Author: your name
 * @Date: 2022-03-29 16:38:39
 * @LastEditTime: 2022-03-29 18:34:32
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nbh_gys_acadmin\src\http\nb\store.js
 */
const base = APPLICATION_ENV.USER_API
const storeApis = {
  store: {
    list: base + 'back-supplier/store/list', // 店铺列表
    view: base + 'back-supplier/store/view', // 店铺查看
    add: base + 'back-supplier/store/add', // 店铺新增
    delete: base + 'back-supplier/store/delete', // 店铺删除
    edit: base + 'back-supplier/store/edit', // 店铺编辑
    disable: base + 'back-supplier/store/disable', // 店铺禁用
    enable: base + 'back-supplier/store/enable' // 店铺启用
  }
}

export default storeApis
