
import { Component, Vue } from 'vue-property-decorator'
import { getQueryString } from '@/utils/utils'

@Component({
  name: 'App'
})
export default class extends Vue {
  mounted() {
    let token = getQueryString('token')
    if (token) {
      localStorage.setItem('token', token)
    }

    // 路由中配置hideNav=1，保存到缓存中，隐藏导航栏
    let hideNav = getQueryString('hideNav')
    if(hideNav) {
      window.sessionStorage.setItem('hideNav', hideNav)
    }
  }
}
