<template>
  <div
    class="page-content"
    :style="{'background': background}"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: 'PageContent',
  props: {
    background: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>
<style lang="scss" scoped>
.page-content{
  margin: 20px;
  padding: 20px;
}
</style>
