const environment = getEnvironment();
const platformConfigList = [
  {
    code: 3300,
    name: '网上农博',
    loginLogo: 'https://nb-img.hzanchu.com/h5/nbh_admin/img/logo.png', // 登录页-展示logo
    assSpecId: 47989, // 组合规格id，组合商品使用
    siteId: 0, // 查询展馆下拉列表参数
    applicationShow: true, // 登录页-“我要开店”
    urlKeyword: 'nb-', // url标识
    goodsCatId: 1, // 商品顶级类目
    agreementTitle: '《网上农博平台合作协议》', // 登录页-展示的协议
    agreementUrl: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/h5/nbh_gys_admin/cdn/%E7%BD%91%E4%B8%8A%E5%86%9C%E5%8D%9A%E5%B9%B3%E5%8F%B0%E5%90%88%E4%BD%9C%E5%8D%8F%E8%AE%AE.pdf',
    operatingEnvironment: environment, // 环境
    h5Url: { // 当前环境h5地址
      master: 'https://wsnb.hzanchu.com',
      pre: 'https://pre-wsnb.hzanchu.com',
      test: 'https://wsnb-test.hzanchu.com',
    }[environment],
  },
  {
    code: 3100,
    name: '鱼米之乡',
    loginLogo: 'https://nb-img.hzanchu.com/h5/nbh_admin/img/shanghai.png',
    assSpecId: 47989,
    siteId: '41217198239621120',
    applicationShow: false,
    urlKeyword: 'sh-',
    goodsCatId: 1,
    agreementTitle: '《鱼米之乡平台合作协议》',
    agreementUrl: 'https://wsnbh-img.hzanchu.com/test/51351931115896832.pdf',
    operatingEnvironment: environment,
    h5Url: { // 当前环境h5地址
      master: 'https://wsnb.hzanchu.com',
      pre: 'https://pre-wsnb.hzanchu.com',
      test: 'https://wsnb-test.hzanchu.com',
    }[environment],
  },
  {
    code: 5100,
    name: '川府农博',
    loginLogo: 'https://nb-img.hzanchu.com/h5/nbh_admin/img/logo.png',
    assSpecId: 47989,
    siteId: 0,
    applicationShow: false,
    urlKeyword: 'sc-',
    goodsCatId: 1,
    agreementTitle: '《川府农博平台合作协议》',
    agreementUrl: 'https://wsnbh.oss-accelerate.aliyuncs.com/h5/nbh_gys_admin/pdf/%E3%80%8A%E5%B7%9D%E5%BA%9C%E5%86%9C%E5%8D%9A%E5%B9%B3%E5%8F%B0%E5%90%88%E4%BD%9C%E5%8D%8F%E8%AE%AE%E3%80%8B.pdf',
    operatingEnvironment: environment,
    h5Url: { // 当前环境h5地址
      master: 'https://sc.zjnongbo.com',
      pre: 'https://pre-sc.zjnongbo.com',
      test: 'https://test-sc.zjnongbo.com',
    }[environment],
  },
];
const projectCode = getProjectCode();
localStorage.setItem('projectCode', projectCode);
console.log(projectCode, '根据域名判断code========');
const platformConfigObj = platformConfigList.reduce((start, result) => {
  const newObj = Object.assign(start, {
    [result.code]: result,
  });
  return newObj;
}, {});
const projectConfigDetail = platformConfigObj[projectCode] || platformConfigObj['3300'];
// 获取运行环境
function getEnvironment() {
  const { hostname } = window.location;
  if (hostname.includes('pre')) return 'pre';
  if (hostname.includes('test')) return 'test';
  if (hostname.includes('.com')) return 'master';
  return 'test';
}
function getProjectCode() {
  const name = window.location.hostname;
  let code = 3300;
  for (let item of platformConfigList) {
    if (name.includes(item.urlKeyword)) {
      code = item.code;
    }
  }
  return code;
}

export {
  platformConfigList,
  platformConfigObj,
  projectConfigDetail, // 当前平台的数据
};
