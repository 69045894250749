// 新船-商品模块
const base = APPLICATION_ENV.GOODS_API;
// const base = 'http://10.10.11.31:8087/'
const goodsApis = {
  trade_template_mail: {
    list: `${base}back-supplier/trade-template-mail/list`, // 运费模板列表
    add: `${base}back-supplier/trade-template-mail/add`, // 运费模板新增
    detail: `${base}back-supplier/trade-template-mail/detail`, // 运费模板详情
    edit: `${base}back-supplier/trade-template-mail/edit`, // 运费模板修改
    remove: `${base}back-supplier/trade-template-mail/remove`, // 删除
    getNotify: `${base}file/getCommonCache`, // 获取通知
  },
  trade_template_self: {
    add: `${base}back-supplier/trade-template-self/add`, // 自提新增
    edit: `${base}back-supplier/trade-template-self/edit`, // 自提编辑
    remove: `${base}back-supplier/trade-template-self/remove`, // 自提删除
  },
  goodsmanage: {
    trade_goods_page: `${base}back-supplier/goodsmanage/trade-goods/page`, // 查询商品列表(分页)
    trade_goods_modify: `${base}back-supplier/goodsmanage/trade-goods/modify`, // 商品上下架
    trade_goods_self_status: `${base}back-supplier/goodsmanage/trade-goods/self_status`, // 设置自提状态
    trade_goods_detail: `${base}back-supplier/goodsmanage/trade-goods/detail`, // 查看商品
    trade_goods_addSpec: `${base}back-supplier/goodsmanage/trade-goods/addSpec`, // 新增商品规格
    trade_goods_editSpec: `${base}back-supplier/goodsmanage/trade-goods/editSpec`, // 辑商品规格
    trade_goods_showSpec: `${base}back-supplier/goodsmanage/trade-goods/showSpec`, // 回显商品规格
    trade_goods_deleteSpec: `${base}back-supplier/goodsmanage/trade-goods/deleteSpec`, // 删除商品规格
    trade_goods_add: `${base}back-supplier/goodsmanage/trade-goods/add`, // 新增商品
    trade_goods_edit: `${base}back-supplier/goodsmanage/trade-goods/edit`, // 编辑商品
    trade_goods_remove: `${base}back-supplier/goodsmanage/trade-goods/remove`, // 删除商品
    trade_goods_audit: `${base}back-supplier/goodsmanage/trade-goods/audit`, // 审核通过/拒绝
    trade_goods_recommended: `${base}back-supplier/goodsmanage/trade-goods/recommended`, // 置顶商品
    trade_goods_housePriceManage_view: `${base}back-supplier/goodsmanage/trade-goods/housePriceManage/view`, // 房价管理-查看
    trade_goods_housePriceManage_edit: `${base}back-supplier/goodsmanage/trade-goods/housePriceManage/edit`, // 房价管理-编辑
    trade_goods_closeHouse: `${base}back-supplier/goodsmanage/trade-goods/housePriceManage/closeHouse`, // 房价管理-关闭房源
    trade_goods_openHouse: `${base}back-supplier/goodsmanage/trade-goods/housePriceManage/openHouse`, // 房价管理-开启房源
    housePriceSetting_edit: `${base}back-supplier/goodsmanage/trade-goods/housePriceSetting/edit`, // 房价设置-默认房价-编辑保存
    housePriceSetting_view: `${base}back-supplier/goodsmanage/trade-goods/housePriceSetting/view`, // 房价设置-默认房价--查看
    trade_goods_checkExchange: `${base}back-supplier/goodsmanage/trade-goods/checkExchange`, // 参加商家活动的商品编辑校验（换购活动）
    add_goods_stock: `${base}back-supplier/goodsmanage/trade-goods/add-goods-stock`, // 增加库存
    sku_list: `${base}back-supplier/goodsmanage/trade-goods/sku-list`, // sku列表
    getSkuByCondition: `${base}back-supplier/goodsmanage/trade-goods/getSkuByCondition`, // 根据查询条件获取sku明细,按更新时间倒序排序
    trade_goods_batchCloseHouse: `${base}back-supplier/goodsmanage/trade-goods/housePriceManage/batchCloseHouse`, // 房价管理-批量关闭房源
    trade_goods_batchOpenHouse: `${base}/back-supplier/goodsmanage/trade-goods/housePriceManage/batchOpenHouse`, // 房价管理-批量开启房源
    trade_goods_baseGuarantee: `${base}/back-supplier/goodsmanage/trade-goods/goods/baseGuarantee`, // 获取基础保障
    trade_goods_copyGoods: `${base}/back-supplier/goodsmanage/trade-goods/copyGoods`, // 复制商品
  },
  Tagcontroller: {
    Tagcontroller_SearchTagList: `${base}back-supplier/Tagcontroller/SearchTagList`, // 标签不分页查询
  },
  BrandController: {
    SearchBrand: `${base}back-supplier/BrandController/SearchBrand`, // 品牌查询
  },
  backendCategory: {
    backend_category_list: `${base}back-supplier/backend_category/list`, // 后台类目列表
  },
  nb_common: {
    file_upload: `${base}file/upload`, // 文件上传
    newUpload:`${base}file/newUpload` // 文件上传新接口
  },
  liveGoods: {

    live_goods_select: `${base}back-supplier/goodsmanage/trade-goods/selectGoodsForLive`, // 优惠券商品列表
  },
  promApply: {
    all_site_list: `${base}back-supplier/site/list/test`, // 展馆三级列表
  },

  // 改价记录
  houseChange: {
    change_price_list: `${base}back-supplier/hotelChange/housePriceChange`, // 房屋变更记录
    house_type_list: `${base}back-supplier/hotelChange/specList`, // 房型下拉列表
    modifier_list: `${base}back-supplier/hotelChange/updateUserList`, // 修改人xialaliebiao
    export_list: `${base}back-supplier/hotelChange/housePriceChangeExport`, // 导出
  },
  // 门店管理
  tradeShop: {
    list: `${base}back-supplier/trade-shop/list`, // 门店列表
    delete: `${base}back-supplier/trade-shop/delete`, // 门店删除
    edit: `${base}back-supplier/trade-shop/edit`, // 门店编辑
    save: `${base}back-supplier/trade-shop/save`, // 门店新增
  },
  brand: {
    list: `${base}back-supplier/brand/list`, // 品牌列表
    add: `${base}back-supplier/brand/add`, // 新增品牌
    delete: `${base}back-supplier/brand/delete`, // 删除品牌
    detail: `${base}back-supplier/brand/detail`, // 品牌详情
    edit: `${base}back-supplier/brand/edit`, // 编辑品牌
  },
  attribute: {
    add: `${base}back-supplier/attribute/add`, // 属性创建
    delete: `${base}back-supplier/attribute/delete`, // 属性删除（变为不常用属性）
    list: `${base}back-supplier/attribute/list`, // 属性列表
  },
  goods_supplier_tag: {
    add: `${base}back-supplier/supplier_tag/add`, // 标签创建
    delete: `${base}back-supplier/supplier_tag/delete`, // 标签删除（变为不常用标签）
    page: `${base}back-supplier/supplier_tag/page`, // 标签列表
    tagGroupList: `${base}back-supplier/supplier_tag/tagGroupList`, // 标签列表
  },
  goods_spec: {
    page: `${base}back-supplier/spec/page`, // 规格列表
    add_spec_value: `${base}back-supplier/spec/add_spec_value`, // 新增规格值
    del_spec_value: `${base}back-supplier/spec/del_spec_value`, // 删除规格值
    detail: `${base}back-supplier/spec/detail`, // 规格详情
  },
  eval: {
    list: `${base}/back-supplier/goods-comment/page`, // 列表
    reply: `${base}/back-supplier/goods-comment/reply`, // 恢复评价
  },
  equity: {
    getGoodEquityList: `${base}/back-supplier/equity/getGoodEquityList`, // 获取权益列表
    addGoodEquity: `${base}/back-supplier/equity/addGoodEquity`, // 添加权益
    deleteGoodEquity: `${base}/back-supplier/equity/deleteGoodEquity`, // 删除权益
    getGoodList: `${base}/back-supplier/equity/getGoodList`, // 搜索权益商品列表
    toAudit: `${base}/back-supplier/equity/toAudit`, // 提交审核
    updateGoodEquity: `${base}/back-supplier/equity/updateGoodEquity`, // 更新权益
  },
  supplychain: {
    page: `${base}back-supplier/goodsmanage/supplychain/page`, // 农博优选/供应商--查询选品中心列表(分页)
    pageForAdd: `${base}back-supplier/goodsmanage/supplychain/pageForAdd`, // 供应商-添加供货商品-查看商品列表
    add: `${base}back-supplier/goodsmanage/supplychain/add`, // 供应商-批量添加供货商品
    remove: `${base}back-supplier/goodsmanage/supplychain/remove`, // 供应商-移除供货商品
    relation: `${base}back-supplier/goodsmanage/supplychain/relation`, // 农博优选-一键关联
    unRelation: `${base}back-supplier/goodsmanage/supplychain/unRelation`, // 农博优选-解除关联/解除合作
    unRelationTip: `${base}back-supplier/goodsmanage/supplychain/unRelationTip`, // 农博优选-解除关联/解除合作提示框
    relateGoodsList: `${base}back-supplier/goodsmanage/supplychain/relateGoodsList`, // 农博优选/供应商--已关联商品列表(分页)
    optLogList: `${base}back-supplier/goodsmanage/supplychain/optLogList`, // 农博优选商品操作日志
  }, // 采摘卡2.0
  goods_pick: {
    addGoodPickSku: `${base}back-supplier/pick/addGoodPickSku`, // 添加采摘类目的sku
    updateGoodPickSku: `${base}back-supplier/pick/updateGoodPickSku`, // 更新采摘类目的sku
    getGoodPickSku: `${base}back-supplier/pick/getGoodPickSku`, // 查询采摘类目的sku
    getShopInfoByGoodId: `${base}back-supplier/pick/getShopInfoByGoodId`, // 查询采摘类目的sku
    batchModifyPriceAndStock: `${base}back-supplier/pick/batchModifyPriceAndStock`, // 批量改价和库存
    batchOpera: `${base}back-supplier/pick/batchOpera`, // 批量启售、停售
    modifyOnePriceAndStock: `${base}back-supplier/pick/modifyOnePriceAndStock`, // 指定日期改价和库存
    resetShopSku: `${base}back-supplier/pick/resetShopSku`, // 门店sku数据重新同步
  },
};

export default goodsApis;
