import { onCLS, onFID, onTTFB, onINP, onLCP, onFCP } from 'web-vitals'
import tracker from './config'

function getPerformanceItem(doResolve, timeout=60000) {
  return new Promise(reslove => {
    // 超过时长进行上报
    // 一些预渲染的页面数据不对过滤不会执行回调，https://github.com/GoogleChrome/web-vitals/issues/268
    // const timer = setTimeout(()=>{
    //   reslove({
    //     value: '-1'
    //   })
    // }, timeout)

    doResolve((data) => { 
      // clearTimeout(timer)
      reslove(data)
    })
  })
}

async function getPerformance() {
  const performanceList = await Promise.all([
    getPerformanceItem(onFCP), 
    getPerformanceItem(onLCP), 
    getPerformanceItem(onINP), 
    getPerformanceItem(onTTFB),
    getPerformanceItem(onFID),
    getPerformanceItem(onCLS)
  ])
  const [ FCP, LCP, INP, TTFB, FID, CLS ] = performanceList.map(item => item.value)
  tracker.sendImmediate({
    type: 'performance',
    content: {
      FCP,
      LCP,
      INP,
      FID,
      CLS,
      TTFB,
      browserInfo: navigator.userAgent
    }
  })
}
getPerformance()

let longtaskNumber = 0
let longtaskReportMax = 0
let longtaskTime = null
function longtaskReport() {
  // 每60秒上报一次长任务数
  clearTimeout(longtaskTime)
  // 每个最多上报10次
  if (longtaskReportMax >= 10) return
  longtaskTime = setTimeout(()=>{
    // 存在长任务才上报
    if (longtaskNumber > 0) return
    tracker.sendImmediate({
      type: 'performance-longtask',
      content: {
        number: longtaskNumber,
        page: window.location.pathname,
        browserInfo: navigator.userAgent
      }
    })
    // 上报后数量清零，重新开始计数
    longtaskNumber = 0;
    longtaskReportMax++;
    longtaskReport();
  }, 60000)
}
longtaskReport()

function getResourceType(entry) {
  // 由于PerformanceEntry 只能获取发起方类型HTML标签，所有具体类型类型得按文件名判断

  // 这些是能由发起方判断出来的资源类型加载
  let initiatorTypes = ['xmlhttprequest', 'fetch', 'audio', 'vedio', 'img'] 
  if (initiatorTypes.includes(entry.initiatorType)) return entry.initiatorType

  let resourceType = '';
  // 其余发起方资源类型判断通过资源名判断
  const fileTypes = String(entry.name).replace(/\?\S*/, '').match(/\.[A-z]*$/)
  const fileType = fileTypes && fileTypes[0]
  const fileMatcher = {
    img: ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', 'webp'],
    css: ['.css', '.ttf'],
    script: ['.js']
  }
  for (const [key, value] of Object.entries(fileMatcher)) {
    if (value.includes(fileType)) {
      resourceType = key
      break
    }
  }
  
  return resourceType
}

// 监听反应堆key: callback，监听除了web-vitals
const observerReactive = {
  longtask(entry) {
    // 长任务的监听大于150毫秒, 默认50太苛刻了可以调整
    if (entry.duration > 150)  longtaskNumber++;
  },
  resource(entry) {
    // 资源加载监听
    const resourceType = getResourceType(entry)
    switch (resourceType) {
      case 'script': case 'css': 
        // js、css发起加载时长超过600毫秒的上报
        if (!(entry.duration >= 600)) return
        break
      case 'xmlhttprequest':case 'fetch':
        // http请求大于800毫秒的上报
        if (!(entry.duration >=  800)) return
        break
      case 'audio': case 'vedio': case 'img':
        // audio、vedio、img等流媒体加载大于2000毫秒上报
        if (!(entry.duration >=  2000)) return
        break
      default:
        return
    }
    tracker.sendImmediate({
      type: 'performance-resource',
      content: {
        resourceType, // 类型
        name: entry.name, // 资源名
        size: entry.transferSize,  // 资源大小
        protocol: entry.nextHopProtocol, // 资源加载协议
        time: entry.duration,  // 资源加载时长
        browserInfo: navigator.userAgent // 浏览器信息
      }
    })
  }
}
const observer = new PerformanceObserver((list) => {
  for (const entry of list.getEntries()) {
    const cb = observerReactive[entry.entryType]
    cb && cb(entry)
  }
})
observer.observe({entryTypes: Object.keys(observerReactive)});