const base = APPLICATION_ENV.USER_API
const adminApis = {
  admin: {
    list: base + 'back-supplier/User/list', // 账号列表
    view: base + 'back-supplier/User/viewByMobile', // 账号查看
    add: base + 'back-supplier/User/add', // 账号新增
    delete: base + 'back-supplier/User/delete', // 账号删除
    edit: base + 'back-supplier/User/edit', // 账号编辑
    disable: base + 'back-supplier/User/disable', // 账号禁用
    enable: base + 'back-supplier/User/enable', // 账号启用

    oldVerification: base + 'app-supplier/setting/oldVerification', // 商家手机号修改-旧手机号验证码验证
    newVerification: base + 'app-supplier/setting/newVerification', // 商家手机号修改-新手机号验证码验证
    newCode: base + 'app-supplier/setting/newCode', // 商家手机号修改-新手机号校验是否存在
    codeVerification: base + 'app-supplier/setting/codeVerification', // 商家修改密码验证验证码
    setPassword: base + 'app-supplier/setting/setPassword'// 修改密码

  }
}

export default adminApis
