const base = '/apiadmini/'
const marketApi = {
  actList: base + 'backend/MarketActivity/get_market_activity_list',
  actDetail: base + 'backend/MarketActivity/get_market_activity_detail',
  actGoods: base + 'backend/MarketActivity/get_market_activity_goods',
  actSku: base + 'backend/MarketActivity/get_market_activity_goods_spec',
  saveMsg: base + 'backend/MarketActivity/set_market_activity_goods',
  recordList: base + 'backend/MarketActivity/get_market_activity_record',
  cancelAct: base + 'backend/MarketActivity/cancelMarketActivity',
  addGoodsItem: base + 'backend/MarketActivity/addMarketActivityGoods',
  delGoodsItem: base + 'backend/MarketActivity/delMarketActivityGoods',
  goodsList: base + 'backend/MarketActivity/getMarketActivityGoodsList',
  goodsCateList: base + 'backend/MarketActivity/getMarketActivitySpecList',
  auditGoods: base + 'backend/MarketActivity/setMarketActivityGoodsData',
  actGoodsHotel: base + 'backend/MarketActivity/get_act_goods_hotel_detail', // 民宿商品日期价格库存列表
  setActivityHotelOne: base + 'backend/MarketActivity/set_market_activity_goods_hotel_one', // 保存民宿商品库存价格信息--单条
  cancelActGoodsHotel: base + 'backend/MarketActivity/cancel_act_goods_hotel', // 商家取消活动报名
  set_goods_hotel_act_cache: base + 'backend/MarketActivity/set_goods_hotel_act_cache', // 保存请求接口保存请求接口
  confirm_act_goods_hotel: base + 'backend/MarketActivity/confirm_act_goods_hotel'// 确认以上信息无误
}

export default marketApi
