
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import SidebarItem from './SidebarItem.vue'
import variables from '@/styles/_variables.scss'
import Logo from './Logo.vue'
import { getModule } from 'vuex-module-decorators'
import router from '@/router'

@Component({
  name: 'SideBar',
  components: {
    SidebarItem,
    Logo
  }
})
export default class extends Vue {
  // public isCollapse: boolean = false

  get sidebar() {
    return AppModule.sidebar
  }

  get routes() {
    return (this.$router as any).options.routes
  }

  get variables() {
    return variables
  }

  get isCollapse() {
    return !this.sidebar.opened
  }

  // public menulist = [
  //   {
  //     icon: 'icondianpu',
  //     name: '店铺',
  //     router: '',
  //     index: '-1',
  //     show: true,
  //     children: [
  //       {
  //         icon: '',
  //         index: '-1-1',
  //         name: '店铺概览',
  //         show: true,
  //         router: '/store/dashboard'
  //       },
  //       {
  //         icon: '',
  //         index: '-1-3',
  //         name: '店铺信息',
  //         show: true,
  //         router: '/store/editStore'
  //       },
  //       {
  //         icon: '',
  //         index: '-1-4',
  //         name: '供应商信息',
  //         show: true,
  //         router: '/store/supplier'
  //       },
  //       {
  //         icon: '',
  //         index: '-1-5',
  //         name: '店铺交易数据',
  //         show: true,
  //         router: '/store/tradests'
  //       },
  //       {
  //         icon: '',
  //         index: '-1-6',
  //         name: '店铺商品设置',
  //         show: true,
  //         router: '/store/goodsData'
  //       }
  //     ]
  //   },
  //   {
  //     icon: 'icontianjiashangpin',
  //     name: '添加商品',
  //     router: '/goods/info/detail?type=add',
  //     index: '0',
  //     show: true,
  //     isFirst: true
  //   },
  //   {
  //     icon: 'iconfahuoguanli',
  //     name: '发货管理',
  //     router: '/order/purchase/list',
  //     index: '1',
  //     isFirst: true,
  //     show: true,
  //     isCount: true
  //   },
  //   {
  //     icon: 'iconshangpinx',
  //     name: '商品',
  //     router: '',
  //     index: '2',
  //     show: true,
  //     children: [
  //       {
  //         icon: '',
  //         index: '2-1',
  //         name: '商品列表',
  //         show: true,
  //         router: '/goods/list'
  //       },
  //       {
  //         icon: '',
  //         index: '2-2',
  //         name: '商品审核',
  //         show: true,
  //         router: '/goods/check/list'
  //       },
  //       {
  //         icon: '',
  //         index: '2-3',
  //         name: '商品评价',
  //         show: true,
  //         router: '/goods/comment/list'
  //       }
  //     ]
  //   },
  //   {
  //     icon: 'icondingdanx',
  //     name: '订单',
  //     router: '',
  //     index: '3',
  //     show: true,
  //     children: [
  //       {
  //         icon: '',
  //         index: '3-1',
  //         name: '订单列表',
  //         show: true,
  //         router: '/order/list'
  //       },
  //       {
  //         icon: '',
  //         index: '3-2',
  //         name: '民宿管理',
  //         show: true,
  //         router: '/order/homestay?home=0'
  //       },
  //       {
  //         icon: '',
  //         index: '3-3',
  //         name: '待接单订单',
  //         show: true,
  //         router: '/order/homestay?orderState=19'
  //       },
  //       {
  //         icon: '',
  //         index: '3-4',
  //         name: '退款审核',
  //         show: true,
  //         router: '/order/cancel/list'
  //       },
  //       {
  //         icon: '',
  //         index: '3-5',
  //         name: '物流预警',
  //         show: true,
  //         router: '/order/warning/list'
  //       },
  //       {
  //         icon: '',
  //         index: '3-6',
  //         name: '商品销售列表',
  //         show: true,
  //         router: '/order/goodssale/list'
  //       }
  //     ]
  //   },
  //   {
  //     icon: 'iconcaiwure',
  //     name: '财务',
  //     router: '',
  //     index: '4',
  //     show: true,
  //     children: [
  //       {
  //         icon: '',
  //         index: '4-1',
  //         name: '结算与提现',
  //         show: true,
  //         router: '/finance/SAW'
  //       },
  //       // {
  //       //   icon: '',
  //       //   index: '4-2',
  //       //   name: '结算单列表',
  //       //   router: '/finance/settlement'
  //       // },
  //       {
  //         icon: '',
  //         index: '4-3',
  //         name: '收款信息填写',
  //         show: true,
  //         router: '/finance/supplybank'
  //       }
  //     ]
  //   },
  //   {
  //     icon: 'iconyunyingx',
  //     name: '营销',
  //     router: '',
  //     index: '5',
  //     show: true,
  //     children: [
  //       {
  //         icon: '',
  //         index: '5-1',
  //         name: '优惠券列表',
  //         show: true,
  //         router: '/operation/coupons'
  //       },
  //       {
  //         icon: '',
  //         index: '5-2',
  //         name: '农民说/原逛农博',
  //         show: true,
  //         router: '/operation/nombo'
  //       },
  //       {
  //         icon: '',
  //         index: '5-3',
  //         name: '营销活动列表',
  //         show: true,
  //         router: '/operation/market/index'
  //       },
  //       {
  //         icon: '',
  //         index: '5-4',
  //         name: '拼团',
  //         show: true,
  //         router: '/operation/group'
  //       }
  //     ]
  //   },
  //   {
  //     icon: 'iconrenyuanguanli',
  //     name: '人员管理',
  //     router: '/employ/express/list',
  //     index: '6',
  //     show: true,
  //     isFirst: true,
  //     children: [
  //       {
  //         icon: '',
  //         index: '6-1',
  //         name: '同城配送人员管理',
  //         show: true,
  //         router: '/employ/express/list'
  //       }
  //     ]
  //   },
  //   {
  //     icon: 'iconfahuoguanli',
  //     name: '物流管理',
  //     router: '/freight/list',
  //     index: '7',
  //     show: true,
  //     isFirst: true,
  //     children: [
  //       {
  //         icon: '',
  //         index: '7-1',
  //         name: '运费模板列表',
  //         show: true,
  //         router: '/freight/list'
  //       },
  //       {
  //         icon: '',
  //         index: '7-2',
  //         name: '配送管理',
  //         show: true,
  //         router: '/selfRaise/list'
  //       }
  //     ]
  //   },
  //   {
  //     icon: 'iconfahuoguanli',
  //     name: '直播管理',
  //     router: '/live/activity',
  //     index: '8',
  //     show: false,
  //     isFirst: true,
  //     children: [
  //       {
  //         icon: '',
  //         index: '8-1',
  //         name: '直播活动列表',
  //         show: true,
  //         router: '/live/activity'
  //       },
  //       {
  //         icon: '',
  //         index: '8-2',
  //         name: '直播商品库',
  //         show: true,
  //         router: '/live/goodspond/detail'
  //       }
  //     ]
  //   },
  //   {
  //     icon: 'iconfahuoguanli',
  //     name: '商品模块',
  //     router: '/nb/goods/index',
  //     index: '9',
  //     show: true,
  //     isFirst: true,
  //     children: [
  //       {
  //         icon: '',
  //         index: '9-1',
  //         name: '商品列表',
  //         show: true,
  //         router: '/nb/goods/index'
  //       },
  //       {
  //         icon: '',
  //         index: '9-2',
  //         name: '运费模板',
  //         show: true,
  //         router: '/nb/goods/freight'
  //       }
  //     ]
  //   }
  // ]
  public menulist: any = [];

  mounted() {
    this.getMenuList()
    // this.isPeopleHostel()
    // this.getOrderNum()
    // this.getLivePolice()
  }

  private async getMenuList() {
    const res = await (this as any).$axios.post((this as any).$api.navigation.list)
    if (res.code === 0) {
      this.menulist = [].concat(res.data)
    }
  }

  // 发货数量
  private orderNum = '0'
  private getOrderNum() {
    (this as any).$axios.post((this as any).$api.getOrderNum, {
      status: 0
    }).then((res: any) => {
      if (res.error === 0 && res.data.count) {
        if (res.data.count > 99) {
          this.orderNum = '99+'
        } else {
          this.orderNum = res.data.count + ''
        }
      } else {
        this.orderNum = '0'
      }
    })
  }
  // private getLivePolice() {
  //   // is_actor 1有0没有直播权限
  //   let liveShow = localStorage.getItem('is_actor')
  //   this.menulist[9].show = !!(Number(liveShow) !== 0)
  // }
  // private isPeopleHostel() {
  //   // 【3：仅民宿】【13和23包含民宿】[1：仅农产品]
  //   let suppliersGoodsTypeRole = localStorage.getItem('suppliers_goods_type_role')
  //   const arr4 = this.menulist[4].children || []
  //   const arr6 = this.menulist[6].children || []
  //   const arr0 = this.menulist[0].children || []
  //   if (suppliersGoodsTypeRole === '3' || suppliersGoodsTypeRole === '23') {
  //     this.menulist[2].show = false
  //     arr4[0].show = false // 订单列表
  //     arr4[4].show = false // 物流预警
  //     arr6[3].show = false
  //     this.menulist[7].show = false
  //     this.menulist[8].show = false
  //   } else if (suppliersGoodsTypeRole === '1' || suppliersGoodsTypeRole === '12') {
  //     arr0[4].show = false // 店铺商品设置
  //     arr4[1].show = false // 名宿管理
  //     arr4[2].show = false // 名宿管理待接单订单
  //   }
  // }
}
