import axios from '@/http/axios'
import mixinOption from '../mixins'
import api from '@/http/api'
import _ from 'lodash'

const install = (Vue) => {
  // 实例方法
  Vue.prototype.$axios = axios;
  Vue.prototype.$api = api;
  Vue.prototype._ = _;

  // 混入
  Vue.mixin(mixinOption);
}

export default { install }
