const base = APPLICATION_ENV.USER_API
const settingApis = {
  setting: {
    view: base + 'app-supplier/setting/viewByMobile', // 账号查看
    add: base + 'app-supplier/setting/add', // 账号新增
    delete: base + 'app-supplier/setting/delete', // 账号删除
    edit: base + 'app-supplier/setting/edit', // 账号编辑
    disable: base + 'app-supplier/setting/disable', // 账号禁用
    enable: base + 'app-supplier/setting/enable' // 账号启用
  }
}

export default settingApis
