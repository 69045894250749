const base = APPLICATION_ENV.MARKETING_API
const marketingApis = {
  returnCoupon: {
    page: base + 'supplier/return-coupon/page', // 分页查询
    enable_state: base + 'supplier/return-coupon/enable-state', // 上下架
    package_supplier: base + 'supplier/return-coupon/package-supplier', // 可以使用券包商家
    update: base + 'supplier/return-coupon/update', // 修改
    insert: base + 'supplier/return-coupon/insert', // 新增
    detail: base + 'supplier/return-coupon/detail' // 详情
  }
}

export default marketingApis
