export const isValidUsername = (str: string) => ['admin', 'editor'].indexOf(str.trim()) >= 0

export const isExternal = (path: string) => /^(https?:|mailto:|tel:)/.test(path)


const isIdNum = (value) => {
  const _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  const _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
  const _IDTaiWan = /^[A-Z][0-9]{9}$/;

  return _IDRe18.test(value) || _IDre15.test(value) || _IDTaiWan.test(value);
}

export const id = {
  validator(rule, value, callback) {
    if (!isIdNum(value)) {
      return callback(new Error('请输入正确的身份证号'))
    }
    callback()
  },
  trigger: 'change'
}

export function floatNumber(max=Infinity, min=-Infinity) {
  return {
    validator(rule, value, callback) {
      if (!/(^[0-9]+\.[0-9]{1,2}$)|(^[0-9]*$)/.test(value) && value.trim() !== '') {
        return callback(new Error('请输入正确的数值，最多小数点后两位'))
      }
      if (value > max || value < min) {
        return callback(new Error('数值超出限制'))
      }
      callback()
    },
    trigger: 'change'
  }
}

const usccReg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/
export const uscc = {
  validator(rule, value, callback) {
    if (!usccReg.test(value)) {
      return callback(new Error('统一社会信用代码'))
    }
    callback()
  },
  trigger: 'change'
}


const mobileReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
export const mobile = {
  validator(rule, value, callback) {
    if (!mobileReg.test(value)) {
      return callback(new Error('请输入正确的手机号'))
    }
    callback()
  },
  trigger: 'change'
}
