var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "navbar", attrs: { id: "navbar" } },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { ToggleSideBar: _vm.toggleSideBar },
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.qrCodeColorImage.value,
                  expression: "qrCodeColorImage.value",
                },
              ],
              staticStyle: { display: "flex" },
            },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.qrCodeColorImage.label,
                    placement: "right-start",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "40px", height: "40px" },
                    attrs: { src: _vm.qrCodeColorImage.value, alt: "" },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              class: _vm.msgEntry,
              on: { mouseenter: _vm.enterMsg, mouseleave: _vm.leaveMsg },
            },
            [_c("tozc")],
            1
          ),
          _c(
            "div",
            {
              class: _vm.ruleEntry,
              on: {
                click: _vm.clickRule,
                mouseenter: _vm.enterRule,
                mouseleave: _vm.leaveRule,
              },
            },
            [
              _c("i", { staticClass: "el-icon-s-grid" }),
              _c("span", [_vm._v(" 规则中心 ")]),
            ]
          ),
          _c(
            "div",
            {
              class: _vm.helpEntry,
              on: {
                click: _vm.clickHelp,
                mouseenter: _vm.enterHelp,
                mouseleave: _vm.leaveHelp,
              },
            },
            [
              _c("i", { staticClass: "el-icon-s-help" }),
              _c("span", [_vm._v(" 帮助中心 ")]),
            ]
          ),
          _c(
            "el-popover",
            { attrs: { placement: "bottom", width: "400", trigger: "click" } },
            [
              _c(
                "div",
                {
                  staticClass: "popover",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c("i", { staticClass: "el-icon-s-tools" }),
                  _c(
                    "span",
                    { staticStyle: { cursor: "pointer", color: "#fff" } },
                    [
                      _vm._v(" 微信服务通知 "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down",
                        staticStyle: { color: "#fff" },
                      }),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "code-box" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v(" 步骤一：授权绑定微信，接收服务通知 "),
                ]),
                _c("img", { attrs: { src: _vm.qrCode } }),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(" 打开微信，扫描上方二维码，确认授权绑定 "),
                ]),
                _c("div", { staticClass: "label" }, [
                  _vm._v(" 步骤二：关注【农博公众号】 "),
                ]),
                _c("img", {
                  staticStyle: { width: "230px" },
                  attrs: {
                    src:
                      _vm.projectCode === "5100"
                        ? "https://wsnbh-img.hzanchu.com/pro/353844964738355200.jpg"
                        : "https://wsnbh-img.hzanchu.com/acimg/1636709463351.png",
                  },
                }),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(
                    " 方式一：打开微信，扫描上方二维码，操作确认关注" +
                      _vm._s(
                        _vm.projectCode === "5100"
                          ? "【川府农博】公众号"
                          : "【农博公众号】"
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(
                    " 方式二：打开微信，搜索" +
                      _vm._s(
                        _vm.projectCode === "5100"
                          ? "【川府农博】"
                          : "【网上农博】"
                      ) +
                      "微信公众号。点击【关注】并确认 "
                  ),
                ]),
                _c("div", { staticClass: "label" }, [
                  _vm._v(" 步骤三：微信服务通知 "),
                ]),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(
                    " 授权绑定的微信将接收到该店铺的服务通知。主要有订单通知、售后通知、提现通知等。 "
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click", placement: "bottom-start" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: {
                    src:
                      _vm.userInfo.headPic ||
                      "https://wsnbh-img.hzanchu.com/acimg/59a126725e7274aacc6683e38d93dbbf.png",
                  },
                }),
                _c("img", {
                  staticClass: "user-avatar2",
                  staticStyle: { display: "none" },
                  attrs: { src: "", alt: "" },
                }),
                _c("p", { staticClass: "user-name" }, [
                  _vm._v(" " + _vm._s(_vm.userInfo.userNickname) + " "),
                ]),
                _c("i", { staticClass: "el-icon-arrow-down" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [_c("el-dropdown-item", [_vm._v(" 返回首页 ")])],
                    1
                  ),
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.editSupplierInfo },
                      },
                      [_vm._v(" 编辑资料 ")]
                    ),
                  ]),
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: {
                          click: function ($event) {
                            _vm.pswVisible = true
                          },
                        },
                      },
                      [_vm._v(" 修改密码 ")]
                    ),
                  ]),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v(" 退出登录 ")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialogShow
        ? _c("edit-info", {
            on: { closeDialog: _vm.closeDialog, getInfo: _vm.getInfo },
          })
        : _vm._e(),
      _c("change-psw", {
        attrs: { "psw-visible": _vm.pswVisible },
        on: { cancelPsw: _vm.cancelPsw },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }