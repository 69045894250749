var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isSoboten
      ? _c("div", { on: { click: _vm.tokf } }, [
          _c("i", { staticClass: "icons el-icon-chat-dot-round" }),
          _vm._v("用户消息"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }