// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.sidebar-container .horizontal-collapse-transition {\n  -webkit-transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;\n  transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;\n}\n.sidebar-container .scrollbar-wrapper {\n  overflow-x: hidden !important;\n}\n.sidebar-container .el-scrollbar__view {\n  height: 100%;\n}\n.sidebar-container .el-scrollbar__bar.is-vertical {\n  right: 0px;\n}\n.sidebar-container .el-scrollbar__bar.is-horizontal {\n  display: none;\n}\n.menu-wrapper .router-link-active .is-active {\n  background: #3FB265 !important;\n}\n.el-menu-item:hover {\n  background: #3FB265 !important;\n  color: #fff !important;\n}\n.el-menu-item:hover .iconfont {\n  color: #fff;\n}\n.el-submenu__title:hover {\n  background: #3FB265 !important;\n  color: #fff !important;\n}\n.el-submenu__title:hover .iconfont {\n  color: #fff;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
