import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'
import { Route } from 'vue-router'
import { UserModule } from '@/store/modules/user'
import { getQueryString } from '@/utils/utils'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login']

router.beforeEach(async(to: Route, _: Route, next: any) => {
  // Start progress bar
  NProgress.start()
  // Determine whether the user has logged in
  let token = localStorage.getItem('token')
  if (to.meta.signIn) {
    next()
  } else if (token || getQueryString('token')) { // 有token直接进入当前页面
    next()
  } else { // 没有token
    if (to.path === '/login') { // 直接进入登录页
      next()
    } else {
      console.log(router)
      next({
        path: '/login',
        query: {
          redirect: (router as any).history.pending.fullPath
        }
      })
      NProgress.done()
    }
  }
})

router.afterEach((to: Route) => {
  // Finish progress bar
  NProgress.done()

  // set page title
  document.title = to.meta.title
})
