var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-pagination",
    _vm._g(
      _vm._b(
        {
          staticClass: "other-pagination",
          attrs: {
            background: "",
            "page-sizes": [10, 20, 30, 50],
            layout: "total, sizes, prev, pager, next, jumper",
          },
        },
        "el-pagination",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }