const base = APPLICATION_ENV.USER_API;
const supplierApis = {
  supplier: {
    view: `${base}back-supplier/supplier/view`, // 查看登陆商家信息
    mineDetail: `${base}back-supplier/supplier/mine/detail`, // 查看登陆商家信息
    mine: `${base}back-supplier/supplier/mine`, // 查看登陆商家信息
    submitReview: `${base}back-supplier/supplier/submitReview`, // 登陆商家信息修改提交审核
    getReviewInfo: `${base}SupplierRegister/getReviewInfo`, // 获取当前登陆用户入驻信息（包含资质、法人等入驻信息）
    change_info: `${base}/back-supplier/supplier/change_info`, // 商家修改注册内容并提交审核
  },
};

export default supplierApis;
