import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import {
  login,
  logout,
  getUserInfo
} from '@/service/userService'

import store from '@/store'

export interface IUserState {
  token: string;
  name: string;
  avatar: string;
  introduction: string;
  roles: string[];
  supplier: object;
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = '';
  public name = '';
  public avatar = '';
  public introduction = '';
  public roles: string[] = [];
  public actor = 0;
  public supplier = {}

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar
  }
  @Mutation
  private SET_INTRODUCTION(introduction: string) {
    this.introduction = introduction
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles
  }

  @Mutation
  private SET_ACTOR(isActor: number) {
    this.actor = isActor
    console.log(this.actor, 'mutation')
  }
  @Mutation
  private SET_SUPPLIER(supplier: object) {
    this.supplier = supplier
  }
  @Action
  public async Login(userInfo: { username: string; password: string }) {
    let { username, password } = userInfo
    username = username.trim()
    const { data, code } = await login({ mobile: username, code: password, loginType: 1 })
    localStorage.setItem('token', data.token)
    this.SET_SUPPLIER(data)
    this.SET_TOKEN(data.token)
    return { data, code }
  }

  @Action
  public ResetToken() {
    this.SET_TOKEN('')
    this.SET_ROLES([])
  }

  // @Action
  // public async GetUserInfo() {
  //   if (this.token === '') {
  //     throw Error('GetUserInfo: token is undefined!')
  //   }
  //   const { data } = await getUserInfo({
  //     /* Your params here */
  //   })
  //   if (!data) {
  //     throw Error('Verification failed, please Login again.')
  //   }
  //   const { roles, name, avatar, introduction } = data.user
  //   // roles must be a non-empty array
  //   if (!roles || roles.length <= 0) {
  //     throw Error('GetUserInfo: roles must be a non-null array!')
  //   }
  //   this.SET_ROLES(roles)
  //   this.SET_NAME(name)
  //   this.SET_AVATAR(avatar)
  //   this.SET_INTRODUCTION(introduction)
  // }

  // @Action
  // public async LogOut() {
  //   if (this.token === '') {
  //     throw Error('LogOut: token is undefined!')
  //   }
  //   await logout()
  //   this.SET_TOKEN('')
  //   this.SET_ROLES([])
  // }
}

export const UserModule = getModule(User)
