<template>
  <el-dialog
    title="修改密码"
    :visible.sync="pswVisible"
    size="tiny"
    :append-to-body="true"
    @close="close"
  >
    <el-form
      ref="ruleForm"
      label-width="100px"
      :rules="rules"
      :model="ruleForm"
    >
      <el-form-item
        label="原密码"
        required
        prop="old_password"
      >
        <el-input
          v-model="ruleForm.old_password"
          type="password"
          class="input-w300"
        />
      </el-form-item>
      <el-form-item
        label="新密码"
        required
        prop="new_password"
      >
        <el-input
          v-model="ruleForm.new_password"
          type="password"
          class="input-w300"
        />
      </el-form-item>
      <el-form-item
        label="新密码确认"
        required
        prop="re_psw"
      >
        <el-input
          v-model="ruleForm.re_psw"
          type="password"
          class="input-w300"
        />
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="cancel">取 消</el-button>
      <el-button
        type="primary"
        @click="setPsw('ruleForm')"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'ChangePsw',
  props: {
    pswVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    var checkRepsw = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('再次输入新密码'))
      } else if (value !== this.ruleForm.new_password) {
        callback(new Error('两次输入的新密码不一致,请重新输入'))
      } else {
        callback()
      }
    }
    return {
      old_password: '',
      new_password: '',
      re_psw: '',
      ruleForm: {
        old_password: '',
        new_password: '',
        re_psw: ''
      },
      rules: {
        old_password: [{ type: 'string', required: true, message: '请输入原密码' }],
        new_password: [{ type: 'string', required: true, message: '请输入新密码' }],
        re_psw: [{ required: true, validator: checkRepsw }]
      }
    }
  },
  methods: {
    setPsw(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let userInfo = JSON.parse(localStorage.getItem('userInfo'))
          this.$axios({
            method: 'post',
            url: this.$api.admin.setPassword,
            data: {
              oldPassword: this.ruleForm.old_password,
              newPassword: this.ruleForm.new_password,
              mobile: userInfo.userMobile
            }
          }).then((res) => {
            let r = res
            if (r.code === 0) {
              this.$message.success('修改成功')
              this.$emit('cancelPsw')
            }
          })
        }
      })
    },
    cancel() {
      this.$emit('cancelPsw')
    },
    close() {
      this.$emit('cancelPsw')
    }
  }
}
</script>
