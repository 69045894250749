var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: [
        "menu-wrapper",
        _vm.isCollapse ? "simple-mode" : "full-mode",
        { "first-level": _vm.isFirstLevel },
      ],
    },
    [
      _vm.item.type === 1
        ? [
            _vm.item.router
              ? _c(
                  "sidebar-item-link",
                  { attrs: { to: _vm.resolvePath(_vm.item.router) } },
                  [
                    _c(
                      "el-menu-item",
                      {
                        class: { "submenu-title-noDropdown": _vm.isFirstLevel },
                        attrs: { index: _vm.resolvePath(_vm.item.router) },
                      },
                      [
                        _vm.item.icon
                          ? _c("i", { class: ["iconfont", _vm.item.icon] })
                          : _vm._e(),
                        _vm.item.name
                          ? _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(_vm._s(_vm.item.name))]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.item.type === 0
        ? _c(
            "el-submenu",
            {
              attrs: {
                index: _vm.resolvePath(_vm.item.router),
                "popper-append-to-body": "",
              },
            },
            [
              _c("template", { slot: "title" }, [
                _vm.item.icon && _vm.item.icon !== 0
                  ? _c("i", { class: ["iconfont", _vm.item.icon] })
                  : _vm._e(),
                _vm.item.name
                  ? _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v(_vm._s(_vm.item.name)),
                    ])
                  : _vm._e(),
              ]),
              _vm.item.children
                ? _vm._l(_vm.item.children, function (child) {
                    return _c("sidebar-item", {
                      key: child.navigationId,
                      staticClass: "nest-menu",
                      attrs: {
                        item: child,
                        "is-collapse": _vm.isCollapse,
                        "is-first-level": false,
                        "base-path": _vm.resolvePath(child.router),
                      },
                    })
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }