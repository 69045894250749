var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改密码",
        visible: _vm.pswVisible,
        size: "tiny",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.pswVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-width": "100px",
            rules: _vm.rules,
            model: _vm.ruleForm,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "原密码", required: "", prop: "old_password" } },
            [
              _c("el-input", {
                staticClass: "input-w300",
                attrs: { type: "password" },
                model: {
                  value: _vm.ruleForm.old_password,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "old_password", $$v)
                  },
                  expression: "ruleForm.old_password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "新密码", required: "", prop: "new_password" } },
            [
              _c("el-input", {
                staticClass: "input-w300",
                attrs: { type: "password" },
                model: {
                  value: _vm.ruleForm.new_password,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "new_password", $$v)
                  },
                  expression: "ruleForm.new_password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "新密码确认", required: "", prop: "re_psw" } },
            [
              _c("el-input", {
                staticClass: "input-w300",
                attrs: { type: "password" },
                model: {
                  value: _vm.ruleForm.re_psw,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "re_psw", $$v)
                  },
                  expression: "ruleForm.re_psw",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.setPsw("ruleForm")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }