import { platformConfigList } from '@/utils/projectConfig';

interface sortProps {
  sort_content?: string;
  sort_name: string;
}

interface rowProps {
  prop: string;
  order?: string;
}

interface imgProps {
  url: string;
  w: number;
  h: number;
  r?: number;
  mode?: number;
}

const sortContent = (row: rowProps) => {
  let _sort:sortProps = {
    sort_content: '',
    sort_name: row.prop
  }

  if (row.order === 'ascending') {
    _sort.sort_content = 'asc'
  } else if (row.order === 'descending') {
    _sort.sort_content = 'desc'
  }

  return _sort
}

/**
 * 识别是否支持webp
 * @returns Boolean
 */
export const isSupportWebp:Function = function():boolean {
  const canvas = document.createElement('canvas')
  if ((canvas.getContext && canvas.getContext('2d'))) {
    return canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0
  }
  return false
}

/**
 * 识别是否支持webp
 * @returns Boolean
 */

const formatImage: Function = (imgVal: imgProps): string => {
  const {
    url,
    w,
    h,
    mode = 0,
    r
  } = imgVal
  const urlReg = /(wsnbh-img\.hzanchu\.com)/

  if (urlReg.test(url)) {
    let _URL = ''
    if (r) {
      _URL += `/circle,r_${r}`
    }

    if (mode === 1) {
      _URL += `/resize,m_lfit,h_${h},w_${w}`
    }

    return `${url}?x-oss-process=image/format${isSupportWebp() ? ',webp' : ''}${_URL}`
  } else {
    return url
  }
}
// 获取url参数
export function getQueryString(name:string) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

export {
  sortContent,
  formatImage
}

/**
 * @param {String | number} timeStamp
 * @returns {String} timeString
 */
 export function dateFormat(date: string | number, fmt: string) : string {
  let dateObj: any = new Date(date);
  if (!fmt) fmt = "yyyy-MM-dd hh:mm:ss";

  if (!dateObj || dateObj == null) return null;
  var o = {
    'M+': dateObj.getMonth() + 1, // 月份
    'd+': dateObj.getDate(), // 日
    'h+': dateObj.getHours(), // 小时
    'm+': dateObj.getMinutes(), // 分
    's+': dateObj.getSeconds(), // 秒
    'q+': Math.floor((dateObj.getMonth() + 3) / 3), // 季度
    'S': dateObj.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (dateObj.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  }
  return fmt
}

export function debounce(fn, delay) {
  let timer;

  return function() {
    if(timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      fn();
    }, delay)
  }
}

export function getViewpoortHeight() {
  return document.documentElement.clientHeight;
}
