/*
 * @Author: your name
 * @Date: 2022-03-29 16:38:39
 * @LastEditTime: 2022-03-30 15:45:42
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nbh_gys_acadmin\src\http\nb\manage.js
 */
const base = APPLICATION_ENV.MANAGE_API

export default {
  // 京东物流相关
  jd: {
    add: base + 'backplat/jd/supplier/address/add', // 添加, 发件人设置
    changeDelivery: base + 'backplat/jd/supplier/address/before/changeDelivery', // 修改包裹
    delivery_info: base + 'backplat/jd/supplier/address/delivery_info', // 查看京东物流信息
    delivery: base + 'backplat/jd/supplier/address/delivery', // 京东物流发货
    cancel: base + 'backplat/jd/supplier/address/delivery_intercept', // 京东物流取消/拦截
    detail: base + 'backplat/jd/supplier/address/detail', // 发件人信息
    surface: base + 'backplat/jd/supplier/address/surface', // 查看京东物流面单信息
    packageNum: base + 'backplat/jd/supplier/address/package/numb', // 查看京东物流包裹数量
    valuate: base + 'jd_order/valuation/order/valuate', // 京东物流保价金额
    addValuation: base + 'jd_order/valuation/add', // 修改京东物流保价金额
    addPrint: base + 'jd_order/print/add' // 添加打印次数
  },
  storeWarn: {
    illegalList: `${base}back-supplier/violation_statistics/page`, // 违规列表
    appealList: `${base}back-supplier/violation_statistics/approve/page`, // 申诉情况列表
    illegalDetail: `${base}back-supplier/violation_statistics/statistics/detail`, // 违规详情
    appealDetail: `${base}back-supplier/violation_statistics/detail`, // 申诉详情
    getCode: `${base}znm/getCode`, // 获取店铺吗
    addAppeal: `${base}/back-supplier/violation_statistics/submit/approve` // 提交申诉
  },
  feedback: {
    list: base + 'back-supplier/feedback/list', // 投诉列表
    typeList: base + 'consumer/feedback/feedback_type' // 查询投诉类型
  },
  tooth: {
    getSupplierUserInfo: base + 'back-supplier/soboten/getSupplierUserInfo', // 获取商家账号列表
    queryAgentList: base + 'back-supplier/soboten/queryAgentList', // 获取客服
    getToken: base + 'back-supplier/soboten/getToken', // 获取智齿token
    updateServiceInfo: base + 'back-supplier/soboten/updateServiceInfo', // 更新客服信息
    bindRelation: base + 'back-supplier/soboten/bindRelation', // 绑定
    singleLogin: base + 'back-supplier/soboten/singleLogin' // 单点登录
  },
  // 品牌认证
  brand_certification: {
    page: base + 'back-supplier/brand_certification/page', // 列表
    detail_by_id: base + 'back-supplier/brand_certification/detail_by_id', // 详情
    operate_status: base + 'back-supplier/brand_certification/operate_status', // 品牌审核
    remove: base + 'back-supplier/brand_certification/remove', // 品牌删除
    edit: base + 'back-supplier/brand_certification/edit' // 编辑
  },
  // 专题模板
  tradePluginTemplate: {
    add: base + 'back-supplier/template/add', // 专题模板新增
    detail: base + 'back-supplier/template/detail', // 专题模板详情
    edit: base + 'back-supplier/template/edit', // 专题模板编辑
    edit_info: base + 'back-supplier/template/editTemplateInfo', // 编辑模板信息
    getListCateGoods: base + 'back-supplier/template/getListCateGoods', // 专题模板分类商品
    getListGoods: base + 'back-supplier/template/getListGoods', // 专题模板列表商品
    page: base + 'back-supplier/template/page', // 专题模板列表
    remove: base + 'back-supplier/template/remove', // 专题模板删除
    selectGoods: base + 'back-supplier/template/selectGoods' // 查询商品信息

    // get_prom_list: base + 'backplat/mallorder/trade-plugin-template/getPromGoodsGrouping',
    // get_select_prom_list: base + 'backplat/mallorder/trade-plugin-template/selectedPromGoodsGrouping',
    // get_prom_goods_list: base + 'backplat/mallorder/trade-plugin-template/getGoodsInfoByGroupId',
    // group_list: base + 'backplat/mallorder/trade-plugin-template/listProm', // 专题模板获取大促分组列表
    // group_goods_list: base + 'backplat/mallorder/trade-plugin-template/selectPromGoods' // 专题模板通过分组id获取商品
  },
  tradePluginTemplateData: {
    list: base + '/backplat/tradePluginTemplateData/list' // 专题模板数据列表
  }
}
