<template>
  <div>
    <div v-if="isSoboten" @click="tokf"><i class="icons el-icon-chat-dot-round"></i>用户消息</div>
  </div>
</template>

<script>
import {localSet} from 'ldx-utils';

export default {
  data() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    return {
      isSoboten: false,
      token: '',
      onlineUrl: '',
      loginParams: {}
    };
  },
  mounted() {
    this._isSoboten();
  },
  methods: {
    async _isSoboten() {
      const params = {supplierUserId: this.user.userId};
      const {code, data} = await this.$axios.get(this.$api.back_supplier.isSoboten, {params});
      if (code === 0) {
        this.isSoboten = data;
        localSet('isSoboten', data);
      }
    },
    getToken() {
      return new Promise(async (resolve, reject) => {
        const params = {supplierId: this.user.suppliersId, supplierUserId: this.user.userId};
        const {code, data} = await this.$axios.get(this.$api.tooth.getToken, {params});
        if (code === 0) {
          const {appId, appKey, email} = data.companyInfoDTO;
          this.token = data.token;
          this.loginParams = {appId, appKey, email};
          resolve();
        } else {
          reject();
        }
      });
    },
    async tokf() {
      if (!this.token) await this.getToken();
      const params = {
        token: this.token,
        email: this.loginParams.email
      };
      const res = await this.$axios.post(this.$api.tooth.singleLogin, params);
      if (res.code === 0) {
        const data = JSON.parse(res.data);
        if (data) this.onlineUrl = data.url;
        window.open(this.onlineUrl, '_blank');
      } else {
        this.token = '';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icons{padding: 0 4px; font-weight: bold;}
</style>