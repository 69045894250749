const base2 = APPLICATION_ENV.SCM_API;
const scmApis = {
  // 供应链
  supplierRegister: {
    myBalance: `${base2}supplier/supply-account/my-balance`, //  我的余额
    // 账户资金明细
    accountDetail: `${base2}supplier/supply-account/detail`,
    // 供应商提现记录
    withdrawPage: `${base2}supplier/supply-withdraw/page`,
    // 提现
    withdrawAdd: `${base2}supplier/supply-withdraw/add`,
  },
  // 供应链 订货退货单
  supplierOrder: {
    // 订货退货单列表
    page: `${base2}supplier/supply-refund/page`,
    // 订货退货单详情
    detail: `${base2}supplier/supply-refund/detail`,
    // 订货退货单导出
    export: `${base2}supplier/supply-refund/export`,
  },
  // 供应链 订货单
  supplierOrderOrder: {
    // 订货单列表
    page: `${base2}supplier/supply_order/page`,
    // 订货单详情
    detail: `${base2}supplier/supply_order/detail`,
    // 订货单导出
    export: `${base2}supplier/supply_order/export`,
    // 订货单发货
    deliver: `${base2}supplier/supply_order/deliver`,
    recordPage: `${base2}supplier/supply_order/record/page`, // 已发货-分页列表
    goodsList: `${base2}supplier/supply_order/goodsList`, // 某订单商品发货情况列表
    importDetail: `${base2}supplier/supply_order/import/detail`, // 导入发货-订单的运单详情（关联商品）
    importEdit: `${base2}supplier/supply_order/import/edit`, // 导入发货-关联商品编辑
    import: `${base2}supplier/supply_order/import`, // 导入发货
    importPage: `${base2}supplier/supply_order/import/page`, // 导入发货-预览分页
    importCancel: `${base2}supplier/supply_order/import/cancel`, // 导入发货-取消
    importSubmit: `${base2}supplier/supply_order/import/submit`, // 导入发货-确认导入
    downloadTemplate: `${base2}supplier/supply_order/download/template`, // 下载导入模板
    downloadInstructions: `${base2}supplier/supply_order/download/instructions`, // 下载发货使用说明
    importDelete: `${base2}supplier/supply_order/import/delete`, // 导入发货-删除
  },
  // 供应链  结算管理
  supplierSettlement: {
    // 对账单列表
    page: `${base2}supplier/supply-settlement/supply/page`,
    // 导出对账单-供应商/优选店铺
    downloadCheckList: `${base2}supplier/supply-settlement/download-check-list`,
    // 导出对账单明细-供应商/优选店铺
    downloadCheckGoods: `${base2}supplier/supply-settlement/download-check-goods`,
    // 结算单列表
    settlementPage: `${base2}supplier/supply-settlement/settlement/page`,
    // 导出结算单明细
    download: `${base2}supplier/supply-settlement/download`,
    // 导出结算单
    downloadSettlement: `${base2}supplier/supply-settlement/download-settlement`,
    // 对账单审核
    audit: `${base2}supplier/supply-settlement/audit`,
    // 对账单明细信息
    detail: `${base2}supplier/supply-settlement/detail`,
    // 对账单商品信息
    goods: `${base2}supplier/supply-settlement/goods`,
    // 对账单操作日志
    log: `${base2}supplier/supply-settlement/log`,
    // 对账单编辑
    edit: `${base2}supplier/supply-settlement/edit`,
  },

  billInvoicing: {
    notInvoiced: `${base2}supplier/supply-settlement/notInvoiced`, // 未处理对账单
    saveDraft: `${base2}supplier/supply-settlement/save/draft`, // 保存草稿 
    saveInvoice: `${base2}supplier/supply-settlement/submit/invoice`, // 提交发票
    companyInfo: `${base2}supplier/supply-settlement/invoice/company/info`,  // 收票信息
    deliveryAddress: `${base2}supplier/supply-settlement/delivery/address`, // 发票收货地址
    invoiceList: `${base2}supplier/supply-settlement/invoice/list`, // 账单发票列表
    invoiceDetail: `${base2}/supplier/supply-settlement/invoice/detail`, // 账单发票详情
    record: `${base2}supplier/supply-settlement/operate/record`, // 操作记录
    audit: `${base2}supplier/supply-settlement/invoice/audit`  // 审核
  }
};

export default scmApis;
