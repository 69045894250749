
<template>
  <el-pagination
    background
    class="other-pagination"
    :page-sizes="[10, 20, 30, 50]"
    layout="total, sizes, prev, pager, next, jumper"
    v-bind="$attrs"
    v-on="$listeners"
  >
  </el-pagination>
</template>

<script>
export default {
   
};
</script>

<style lang="scss" scoped>
    .other-pagination {
        text-align: right;
        margin-top: 0;
        padding: 15px 5px;
    }
</style>
