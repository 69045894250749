
import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { DeviceType, AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import Hamburger from '@/components/Hamburger/index.vue'
import ResizeMixin from '../../mixin/resize'
import editInfo from '@/components/common/editInfo.vue'
import changePsw from '@/components/common/changePsw.vue'
import tozc from './tozc.vue'
import { projectConfigDetail } from '@/utils/projectConfig';

@Component({
  name: 'Navbar',
  components: {
    Breadcrumb,
    Hamburger,
    editInfo,
    changePsw,
    tozc
  }
})

export default class extends mixins(ResizeMixin) {
  created() {}
  mounted() {
    this.getUserInfo()
    this.getQrcode()
    // this.getNum()
    if (this.$route.path === '/store/help') {
      this.helpEntry = 'quick-entry checked'
    } else if (this.$route.path === '/store/message/list') {
      this.msgEntry = 'quick-entry checked'
    } else if (this.$route.path === '/store/wxUser' || this.$route.path === '/store/qrCode') {
      this.quickEntry = 'quick-entry checked'
    } else if (this.$route.path === '/rule/list') {
      this.ruleEntry = 'quick-entry checked'
    }
  }
  get sidebar() {
    return AppModule.sidebar
  }

  get avatar() {
    return UserModule.avatar
  }

  get qrCodeColorImage(){
    let qrImage = {key: -1, value: '',label: ''};
    // 0 绿码 1 红码 3 黄码 -1 无码
    let qrImageArr = [
      { key: -1, value: '',label: '您的店铺暂时未获得浙农码'},
      { key: 0, value: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/acimg/%E7%BC%96%E7%BB%84%209%402x.png',label: '正常运营'},
      { key: 1, value: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/acimg/%E7%BC%96%E7%BB%84%204%402x.png',label:'您的店铺存在严重违规行为,现已下架处理，请尽快完成整改，申请重新上架。'},
      { key: 3, value: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/acimg/%E7%BC%96%E7%BB%84%206%402x.png',label: '您的店铺存在违规行为请尽快完成整改'},
    ]
    qrImage = qrImageArr.find((item)=>{
      return this.userInfo.qrCodeColor === item.key ? item : qrImage
    })
    return qrImage;
  }
  private projectCode: string = localStorage.getItem('projectCode');
  private dialogShow: boolean = false
  private pswVisible: boolean = false

  private qrCode: string = ''
  private closeDialog() {
    this.dialogShow = false
  }

  private cancelPsw() {
    this.pswVisible = false
  }

  private getInfo() {
    console.log(1)
  }

  // 点击规则中心
  private ruleEntry = 'quick-entry'
  private clickRule() {
    (this as any).$router.push('/rule/list')
    this.$router.afterEach((to, from) => {
      if (to.path === '/rule/list') {
        this.msgEntry = 'quick-entry'
        this.quickEntry = 'quick-entry'
        this.helpEntry = 'quick-entry'
        this.appDownload = 'quick-entry'
        if (this.ruleEntry.indexOf('checked') === -1) {
          this.ruleEntry += ' checked'
        }
      } else if (from.path === '/rule/list') {
        this.ruleEntry = 'quick-entry'
      }
    })
  }
  private enterRule() {
    this.ruleEntry = 'quick-entry checked'
  }
  private leaveRule() {
    if (this.$route.path === '/rule/list') {
      this.ruleEntry = 'quick-entry checked'
    } else {
      this.ruleEntry = 'quick-entry'
    }
  }

  // 点击帮助中心
  private helpEntry = 'quick-entry'
  private clickHelp() {
    (this as any).$router.push('/store/help')
    this.$router.afterEach((to, from) => {
      console.log(to)
      if (to.path === '/store/help') {
        this.msgEntry = 'quick-entry'
        this.quickEntry = 'quick-entry'
        this.ruleEntry = 'quick-entry'
        this.appDownload = 'quick-entry'
        if (this.helpEntry.indexOf('checked') === -1) {
          this.helpEntry += ' checked'
        }
      } else if (from.path === '/store/help') {
        this.helpEntry = 'quick-entry'
      }
    })
  }
  private enterHelp() {
    this.helpEntry = 'quick-entry checked'
  }
  private leaveHelp() {
    if (this.$route.path === '/store/help') {
      this.helpEntry = 'quick-entry checked'
    } else {
      this.helpEntry = 'quick-entry'
    }
  }

  // 点击消息中心
  private msgEntry = 'quick-entry'
  private clickMsg() {
    (this as any).$router.push('/store/message/list')
    this.$router.afterEach((to, from) => {
      if (to.path === '/store/message/list') {
        this.helpEntry = 'quick-entry'
        this.appDownload = 'quick-entry'
        this.quickEntry = 'quick-entry'
        this.ruleEntry = 'quick-entry'
        if (this.msgEntry.indexOf('checked') === -1) {
          this.msgEntry += ' checked'
        }
      } else if (from.path === '/store/message/list') {
        this.msgEntry = 'quick-entry'
      }
    })
  }
  private enterMsg() {
    this.msgEntry = 'quick-entry checked'
  }
  private leaveMsg() {
    if (this.$route.path === '/store/message/list') {
      this.msgEntry = 'quick-entry checked'
    } else {
      this.msgEntry = 'quick-entry'
    }
  }

  // 消息类型
  private quickEntry = 'quick-entry'
  private showSelect(bool:Boolean) {
    if (bool) {
      this.quickEntry = 'quick-entry checked'
    } else if (this.$route.path !== '/store/wxUser' && this.$route.path !== '/store/qrCode') {
      this.quickEntry = 'quick-entry'
    }
    this.$router.afterEach((to, from) => {
      if (to.path === '/store/qrCode' || to.path === '/store/wxUser') {
        this.helpEntry = 'quick-entry'
        this.appDownload = 'quick-entry'
        this.msgEntry = 'quick-entry'
        this.ruleEntry = 'quick-entry'
        if (this.quickEntry.indexOf('checked') === -1) {
          this.quickEntry += ' checked'
        }
      } else if (from.path === '/store/qrCode' || from.path === '/store/wxUser') {
        this.quickEntry = 'quick-entry'
      }
    })
  }

  // 商家APP下载
  private appDownload = 'quick-entry'
  private showAppSelect() {
    this.appDownload = 'quick-entry checked'
  }
  private hideAppSelect() {
    this.appDownload = 'quick-entry'
  }

  private toggleSideBar() {
    AppModule.ToggleSideBar(false)
  }

  // 系统消息
  private msgNum = 0
  private async getNum() {
    const res = await (this as any).$axios.post((this as any).$api.Msg.get_unread_num)
    if (res.error === 0) {
      this.msgNum = res.data.num
    } else if (res.error === 1 && res.error_msg.includes('不是供应商')) {
      this.$router.push({ path: '/login' })
    }
  }

  // 获取用户信息
  private userInfo = {qrCodeColor:-1} // 用户信息
  private async getUserInfo() {
    const res = await (this as any).$axios.post((this as any).$api.back_supplier.view)
    if (res.code === 0) {
      this.userInfo = res.data
      // this.userInfo.qrCodeColor = 3
      // this.$store.commit('SET_ACTOR', res.data.is_actor)
      // localStorage.setItem('admin_id', res.data.admin_id)
      // localStorage.setItem('is_actor', res.data.is_actor)
      // localStorage.setItem('suppliers_id', res.data.store_info.suppliers_id)
      // localStorage.setItem('suppliers_goods_type_role', res.data.suppliers_goods_type_role)
      localStorage.setItem('userInfo', JSON.stringify(res.data))
      if (res.data.defaultStoreId > 0) {
        localStorage.setItem('defaultStoreId', res.data.defaultStoreId)
      } else if (!localStorage.getItem('defaultStoreId') || localStorage.getItem('defaultStoreId') == '0') {
        this.$router.push({ path: '/store',
          query: {
            type: 'add',
            id: res.data.suppliersId,
            tipVisible: '0',
            stepsVisible: '0'
          } })
      }
    } else {
      this.userInfo = {qrCodeColor: -1}
    }
  }
  private async getQrcode() {
    const res = await (this as any).$axios.get((this as any).$api.wechat.showQrCode);
    if (res.code !== 0) return;
    this.qrCode = res.data.url
  }

  private async logout() {
    (this as any).$axios.post((this as any).$api.operation.logout).then((res: any) => {
      if (res.code === 0) {
        // sessionStorage.removeItem('menuIndex')
        // this.$store.commit(types.LOGOUT);

        localStorage.removeItem('userInfo')
        localStorage.removeItem('token')
        sessionStorage.clear();
        this.$router.push('/login')
      } else {
        this.$message('你已经退出了请重新登录')
        localStorage.removeItem('token')
        this.$router.push('/login')
      }
    })
  }

  private themeChange() {
    console.log(1)
  }
  private editSupplierInfo() {
    this.$router.push('/nb/setting/account/info')
  }
}
