var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.classObj.mobile && _vm.sidebar.opened && !_vm.hideNav
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      !_vm.hideNav
        ? _c("sidebar", { staticClass: "sidebar-container" })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "main-container",
          style: _vm.hideNav ? { "margin-left": 0 } : null,
        },
        [!_vm.hideNav ? _c("navbar") : _vm._e(), _c("app-main")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }