const base = APPLICATION_ENV.COMMENT_API;
const commentApis = {
  farmer: {
    list: `${base}back-supplier/talk/list`, // 农民说列表
    detail: `${base}back-supplier/talk/detail`, // 农民说查看
    add: `${base}back-supplier/talk/add`, // 农民说新增
    delete: `${base}back-supplier/talk/delete`, // 农民说删除
    edit: `${base}back-supplier/talk/edit`, // 农民说编辑
    pubstatus: `${base}back-supplier/talk/update/pubstatus`, // 农民说上架和下架
    topSort: `${base}back-supplier/talk/top/sort`, // 农民说排序
    recommendList: `${base}back-supplier/talk/recommend/list`, // 农民说推荐
    recommendSort: `${base}back-supplier/talk/recommend/sort`, // 农民说推荐位排序
  },
  topic: {
    list: `${base}topic/list`, // 话题列表
    detail: `${base}topic/detail`, // 话题查看
    add: `${base}topic/add`, // 话题新增
    delete: `${base}topic/delete`, // 话题删除
    edit: `${base}topic/edit`, // 话题编辑
    sort: `${base}topic/top/sort`, // 话题排序
    topicstatus: `${base}topic/update/topicstatus`, // 话题发布与下架
  },

  // 直播相关接口
  live: {
    live_events_list: `${base}back-supplier/tradeLiveActivity/selectLiveActivity`, // 直播活动列表
    live_events_add: `${base}back-supplier/tradeLiveActivity/addLiveActivity`, // 直播活动新增
    live_events_detail: `${base}back-supplier/tradeLiveActivity/selecLiveActivityDetail`, // 直播活动详情
    live_events_edit: `${base}back-supplier/tradeLiveActivity/editorLiveActivity`, // 直播活动编辑
    live_events_delete: `${base}back-supplier/tradeLiveActivity/deleteLiveActivety`, // 直播活动删除
    live_events_share: `${base}back-supplier/tradeLiveActivity/selectLiveActivity`, // 直播活动分享
    anchor_list: `${base}back-supplier/mallcomment/trade-live-actor/selectLiveActorList`, // 获取主播列表

    pull_msg: `${base}back-supplier/tradeLiveActivity/getPullAddress`, // 获取拉流地址
    push_msg: `${base}back-supplier/tradeLiveActivity/getPushAddress`, // 获取推流地址
    live_control_msg: `${base}back-supplier/tradeLiveActivity/getLiveInfoOnLiveManager`, // 在直播控制台获取直播信息
    live_goods_list: `${base}back-supplier/tradeLiveActivity/selectLiveGoodsInfo`, // 在直播控制台获取直播商品信息

    live_sure_add: `${base}back-supplier/tradeLiveActivity/addLiveGoods`, // 在直播控制台确认添加商品
    batch_add_live_goods: `${base}back-supplier/tradeLiveActivity/batch_add_live_goods`, // 控制台-批量添加商品
    live_push_goods: `${base}back-supplier/tradeLiveActivity/pushGoods`, // 在直播控制台推送商品
    live_delete_goods: `${base}back-supplier/tradeLiveActivity/deleteLiveGoods`, // 在直播控制台删除商品
    live_top_goods: `${base}back-supplier/tradeLiveActivity/OnTop`, // 在直播控制台置顶商品
    live_control_config: `${base}back-supplier/tradeLiveActivity/selectLiveConfig`, // 在直播控制台获取直播的相关配置
    live_add_coupon: `${base}back-supplier/tradeLiveActivity/addLiveCouponList`, // 在直播控制台批量添加直播优惠券
    live_delete_coupon: `${base}back-supplier/tradeLiveActivity/deleteLiveCoupon`, // 在直播控制台删除直播优惠券
    live_issue_coupon: `${base}back-supplier/tradeLiveActivity/issueCoupon`, // 在直播控制台发放直播优惠券
    live_coupon_list: `${base}back-supplier/tradeLiveActivity/selecLiveCouponList`, // 在直播控制台获取直播优惠券 列表
    live_get_setting: `${base}back-supplier/tradeLiveActivity/selectLiveConfig`, // 获取直播的相关配置
    live_shut_up: `${base}back-supplier/tradeLiveActivity/changeIsShutup`, // 修改是否禁言的设置
    live_open_recording: `${base}back-supplier/tradeLiveActivity/updateOpenRecording`, // 改变是否开启录播状态
    live_is_recommend: `${base}back-supplier/tradeLiveActivity/updateIsRecommend`, // 修改是否推荐的状态
    live_add_vice: `${base}back-supplier/tradeLiveActivity/addViceAccount`, // 添加副账号
    live_delete_vice: `${base}back-supplier/tradeLiveActivity/deleteViceAccount`, // 删除副账号

    live_account_list: `${base}back-supplier/mallcomment/trade-live-actor/list`, // 分页查询账户信息
    live_account_add: `${base}back-supplier/mallcomment/trade-live-actor/add`, // 新增账户信息
    live_account_detail: `${base}back-supplier/mallcomment/trade-live-actor/detail`, // 详情
    live_account_edit: `${base}back-supplier/mallcomment/trade-live-actor/edit`, // 编辑
    live_account_delete: `${base}back-supplier/mallcomment/trade-live-actor/remove`, // 编辑
    live_account_forbid: `${base}back-supplier/mallcomment/trade-live-actor/changeStatus`, // 禁播
    live_account_check: `${base}back-supplier/mallcomment/trade-live-actor/verify`, // 校验账户

    live_report_detail: `${base}back-supplier/tradeLiveActivity/selectLiveReport`, // 举报详情信息
    live_report_list: `${base}back-supplier/tradeLiveActivity/selectLiveReportPage`, // 举报列表详情
    live_data_statistics: `${base}back-supplier/tradeLiveActivity/liveStateicDate`, // 直播数据统计
    liveTransactionDataByTime: `${base}back-supplier/tradeLiveActivity/liveTransactionDataByTime`, // 直播数据统计
    liveTransactionTotalData: `${base}back-supplier/tradeLiveActivity/liveTransactionTotalData`, // 直播数据统计

    // 2023/12/26  直播迭代 福袋
    lucky_bag_add: `${base}back-supplier/luckybag/rule/add`, // 福袋创建
    lucky_bag_delete: `${base}back-supplier/luckybag/rule/delete`, // 福袋删除
    lucky_bag_detail: `${base}back-supplier/luckybag/rule/detail`, // 福袋规则详情查询-根据id查询详情
    lucky_bag_edit: `${base}back-supplier/luckybag/rule/edit`, // 福袋编辑
    lucky_bag_page: `${base}back-supplier/luckybag/rule/page`, // 福袋规则列表查询-分页
    lucky_bag_updateStatus: `${base}back-supplier/luckybag/rule/updateStatus`, // 福袋规则开关
    live_lottery_result: `${base}back-supplier/live/lottery/live_lottery_result`, // 福袋规抽奖结果
    live_edit_patse_image: `${base}back-supplier/tradeLiveActivity/live_edit_patse_image`, // 编辑直播贴图
    updateLiveGoodsInfo: `${base}backplat/tradeLiveActivity/updateLiveGoodsInfo`, // 修改排序
  },

  message: {
    list: `${base}back-supplier/message/list`,
    setRead: `${base}back-supplier/message/setRead`,
    message_list: `${base}back-supplier/message/new_supplier_message_list`, // 新--消息列表
    message_num: `${base}back-supplier/message/not_read_message_num`, // 商家各类型未读消息数量
    read: `${base}back-supplier/message/read_message`, // 商家将一条消息变为已读
    allRead: `${base}back-supplier/message/read_all_message`, // 全部已读
  },
  wechat: {
    showQrCode: `${base}wechat/showQrCode`, // 获取微信授权二维码
  },
  lease: {
    list: `${base}back-supplier/room/page`, // 房屋列表查询
    del: `${base}back-supplier/room/delete`, // 房屋删除
    info: `${base}back-supplier/room/detail`, // 房屋详情
    add: `${base}back-supplier/room/add`, // 房屋新增
    edit: `${base}back-supplier/room/edit`, // 房屋编辑
    intentList: `${base}back-supplier/intent/page`, // 意向列表
    intentDel: `${base}back-supplier/intent/delete`, // 意向用户删除
  },
};

export default commentApis;
