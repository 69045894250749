import './monitor'
import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'
import App from '@/App.vue'
import {NbForm, NbTable} from 'nb-modules';
import 'nb-modules/dist/index.css';
import 'normalize.css'
import '@/styles/index.scss'
import '@/icons/components'
import '@/permission'
import {trackerSend, getUserInfo} from '@/monitor/utils';
// 插件
import selfPlugin from './plugin';



Vue.use(ElementUI);
Vue.use(NbForm);
Vue.use(NbTable);
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})

Vue.use(selfPlugin);

Vue.config.errorHandler = function (e, vm, info) {
  // vue 上报错误
  const userInfo = getUserInfo()
  console.error(e);
  trackerSend('jsError', {
      message: e.message,
      stack: e.stack,
      browserInfo: navigator.userAgent,
      component: vm.$vnode && vm.$vnode.componentOptions && vm.$vnode.componentOptions.tag,
      vueLifecycle: info,
      userId: userInfo.userId,
      mobile: userInfo.mobile,
      url: location.href
  })
}

// 组件
// @ts-ignore
const componentFiles = require.context('./components/global', true, /\.vue$/);
componentFiles.keys().forEach(e => {
  const moduleName = e.replace(/^\.\/(.*)\.\w+$/, '$1');
  Vue.component(moduleName, componentFiles(e).default);
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
