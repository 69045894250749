var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑信息",
        visible: _vm.dialogVisible,
        size: "tiny",
        "append-to-body": true,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "80px", "label-position": "left" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户头像" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    name: "multipartFile",
                    action: _vm.$api.nb_common.newUpload,
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess,
                  },
                },
                [
                  _vm.imageUrl
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.imageUrl },
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.changeInfo("head")
                    },
                  },
                },
                [_vm._v(" 确认 ")]
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "用户名" } }, [
            _c("p", [_vm._v(_vm._s(_vm.userName))]),
          ]),
          _c("el-form-item", { attrs: { label: "真实姓名" } }, [
            _c("p", [_vm._v(_vm._s(_vm.realname))]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "手机号" } },
            [
              _vm.editMobile
                ? _c("el-input", {
                    staticClass: "input-w170",
                    attrs: { maxlength: "11" },
                    model: {
                      value: _vm.mobile,
                      callback: function ($$v) {
                        _vm.mobile = $$v
                      },
                      expression: "mobile",
                    },
                  })
                : _vm._e(),
              !_vm.editMobile
                ? _c("span", [_vm._v(_vm._s(_vm.mobile))])
                : _vm._e(),
              !_vm.editMobile
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.changeMobile },
                    },
                    [_vm._v(" 修改 ")]
                  )
                : _vm._e(),
              _vm.editMobile
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.changeInfo("mobile")
                        },
                      },
                    },
                    [_vm._v(" 确认 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }